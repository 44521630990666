import {
  ChangePasswordPayload,
  LoginDc,
  RegisterUserDc,
  ResetPasswordCallbackPayload,
  ResetPasswordParams,
  SocAuthNetwork,
} from "@evergis/api";
import { UsedProjectDc } from "@evergis/api/dist/__generated__/data-contracts";
import { UserInfoWithPhoto } from "@evergis/api/dist/services/Account";

import { api } from "./api";
import { SetEmailAndPassword, ILimits, ChangeEmail } from "./types";

export const signIn = (payload: LoginDc) => {
  return api.init({
    authParams: payload,
    fetchSettings: false,
    fetchUser: true,
    useJwt: Boolean(process.env.API_USE_JWT),
  });
};

export const signUp = (payload: RegisterUserDc) =>
  api.account.registerUser(payload);

export const checkLogin = (payload: { username: string }) =>
  api.account.isUsernameExists(payload);

export const checkEmail = (payload: { email: string }) =>
  api.account.isEmailExists(payload);

export const resetPassword = (payload: ResetPasswordParams) =>
  api.account.resetPassword(payload);

export const restorePassword = (payload: ResetPasswordCallbackPayload) =>
  api.account.resetPasswordCallback(payload);

export const fetchUserInfo = async () => {
  if (api.account.isAuth) {
    return api.account.user;
  }

  return (await api.account.getUserInfo(""));
};

export const fetchExtendedUserInfo = async () => {
  return (await api.account.getExtendedUserInfo(api.account.username));
};

export const changePassword = (payload: ChangePasswordPayload) =>
  api.account.changePassword(payload);

export const editUser = (payload: UserInfoWithPhoto) =>
  api.account.updateCurrentUser(payload);

export const uploadPhoto = (file?: File, payload?: { base64Image?: string }) =>
  api.accountPreview.updateCurrentUserPhoto(file, payload);

export const deletePhoto = () =>
  api.accountPreview.deleteCurrentUserPhoto();

export const changeEmail = (payload: ChangeEmail) =>
  api.account.changeEmail(payload);

export const setPassword = (payload: string) =>
  api.account.setPassword(payload);

export const setEmailAndPassword = (payload: SetEmailAndPassword) =>
  api.account.setEmail(payload);

export const unbindSocialNetwork = (network: SocAuthNetwork) =>
  api.external.unbind(network);

export const getUsedProjects = () =>
  api.account.getUsedProjects();

export const getLastProjects = async () => {
  const projectsList = await getUsedProjects();

  if (typeof window === "object" && projectsList && projectsList.length) {
    const response = await api.projects.getProjectInfos(
      projectsList.map((project: UsedProjectDc) => project.name)
    );

    return response.filter((project: UsedProjectDc) => project.name !== "Error");
  }
};

export const checkLimits = async (): Promise<ILimits> => {
  const limits = await api.resourceCatalog.checkLimits({});

  return {
    allowedServiceTypes: limits.allowedServiceTypes || [],
    currentProjectCount: limits.currentProjectCount || 0,
    currentServiceCount: limits.currentServiceCount || 0,
    currentTableCount: limits.currentTableCount || 0,
    maxFeaturesInOneTable: limits.maxFeaturesInOneTable || 0,
    maxProjectsCount: limits.maxProjectsCount || 0,
    maxServicesCount: limits.maxServicesCount || 0,
    maxTablesCount: limits.maxTablesCount || 0,
  };
};
