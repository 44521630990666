import { createStructuredSelector } from "reselect";

import { RootState } from "ducks/reducers";
import { getUserSocialErrorSelector, getUserSocialSelector, UserState } from "ducks/user";

const structuredSelectors = createStructuredSelector<RootState, Pick<UserState, "social" | "socialError">>({
  social: getUserSocialSelector,
  socialError: getUserSocialErrorSelector,
});

export default structuredSelectors;
