import { createStructuredSelector } from "reselect";

import { RootState } from "../../ducks/reducers";
import { getUserErrorSelector, getUsernameSelector, UserState } from "../../ducks/user";

const structuredSelectors = createStructuredSelector<RootState, Pick<UserState, "username" | "error">>({
  error: getUserErrorSelector,
  username: getUsernameSelector,
});

export default structuredSelectors;
