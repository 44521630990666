import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Link, Tooltip } from "@evergis/ui";

import { FooterButtons, LinkButton, SocialButtons, SocialDivider } from "./styled";
import { LoginScreenLink, LoginScreenNetwork } from "./types";
import { PortalConfiguration } from "../../ducks/portalConfig";

const SignInFooterComponent: FC<PortalConfiguration["settings"]["loginScreen"]["footer"]> = ({
  logo,
  networks,
  links,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!!logo && <img src={logo} alt="" className="footer-image" />}
      {(networks?.length || links?.length) && (
        <FooterButtons>
          {networks?.length && (
            <SocialButtons>
              {networks?.map(({ icon, url, tooltip }: LoginScreenNetwork, index: number) => (
                <Tooltip key={index} content={tooltip ? t(tooltip) : url || ""} placement={"top"} distance={0} arrow>
                  {(ref?: (node: HTMLElement | null) => void) => (
                    <Link href={url} target={"_blank"}>
                      <IconButton innerRef={ref} kind={icon} />
                    </Link>
                  )}
                </Tooltip>
              ))}
            </SocialButtons>
          )}
          {networks?.length && links?.length && <SocialDivider />}
          {links?.map(({ icon, text, url, tooltip }: LoginScreenLink, index: number) => (
            <Tooltip key={index} content={tooltip ? t(tooltip) : url || ""} placement={"top"} distance={0} arrow>
              {(ref?: (node: HTMLElement | null) => void) => (
                <Link href={url} target={"_blank"}>
                  <LinkButton innerRef={ref} kind={icon}>
                    {text ? t(text) : ""}
                  </LinkButton>
                </Link>
              )}
            </Tooltip>
          ))}
        </FooterButtons>
      )}
    </>
  );
};

export const SignInFooter: FC = memo(SignInFooterComponent);
