import styled, { css } from "styled-components";
import { Link as RLink, LinkProps } from "@reach/router";
import {
  Blank,
  Link as ExtLink,
  Popover as PopoverUI,
  Icon,
  transition,
  fonts,
} from "@evergis/ui";
import { Flex } from "grid-styled";

import { stylesFor } from "../../styles/queries";
import { Image } from "../Image/Image";
import { ColorPreferences, ScrollState } from "./types";

const linkMixin = css<ColorPreferences>`
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;

  &&,
  &&:visited,
  &&:active,
  &&:hover {
    opacity: 0.7;
    color: ${({ dark }) => (dark ? "#fff" : "#000")};
    transition: color ${transition.show};

    &&[data-current="true"] {
      opacity: 1;
      color: ${({ dark }) => (dark ? "#fff" : "#000")};
    }
  }
` as string[];

export const Link = styled(RLink)<ColorPreferences & LinkProps>`
  ${linkMixin};
`;

export const SignInLink = styled(RLink)<ColorPreferences & LinkProps>`
  ${linkMixin};
  padding: 0 2rem;
  font: ${fonts.button};
  text-transform: uppercase;

  &&,
  &&&:visited,
  &&:active,
  &&:hover {
    opacity: 0.87;
    color: ${({ dark }) => (dark ? "#fff" : "#000")};
    transition: color ${transition.show};
  }

  ${stylesFor.mobile} {
    margin-right: -0.5rem;
    padding: 0 1rem;
  }
`;

export const ExternalLink = styled(ExtLink)<ColorPreferences & Partial<LinkProps>>`
  ${linkMixin};
`;

export const Container = styled.div<ColorPreferences & ScrollState>`
  top: 0;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${({ scrollHeight = 0 }) =>
    `calc(5rem - ${Math.min(scrollHeight, 16)}px)`};
  width: 100vw;
  box-sizing: border-box;
  padding: 0 2rem;
  box-shadow: ${({ dark, scrollHeight = 0 }) =>
    dark || scrollHeight < 16
      ? null
      : "0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.12)"};
  background: ${({ dark }) => (dark ? "#000" : "#fff")};
  transition: background ${transition.show};
  z-index: 2;
`;

export const MenuIcon = styled(Icon)<ColorPreferences>`
  height: 2.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  z-index: 12;
  color: ${({ dark }) => (dark ? "#fff" : "#000")};
`;

export const HeaderLink = styled(RLink)`
  position: relative;
  display: flex;
  align-items: center;
  width: 8rem;
  text-decoration: none;
  cursor: pointer;

  ${stylesFor.mobile} {
    margin: auto;
    width: 6rem;
  }
  
  #custom-logo {
    position: absolute;
  }
`;

export const MenuContainer = styled(Flex)<{ show?: boolean }>`
  align-items: center;
  height: 100%;
  margin: auto;

  ${stylesFor.mobile} {
    display: flex;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    background: black;
    flex-direction: column;
    z-index: 11;
    height: 100vh;
    transition: opacity ${({ show }) => show ? transition.hide : transition.show};
    opacity: ${({ show }) => +!!show};
    pointer-events: ${({ show }) => show ? "all" : "none"};
    justify-content: center;

    > a {
      height: 12%;
      width: 70%;
      box-sizing: border-box;
      font-size: 2.2rem;
    }
  }

  ${stylesFor.laptop} {
    > a {
      height: auto;
      padding: 0.45rem 1rem;
    }
  }
`;

export const RightButtonsContainer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  width: auto;
  height: 100%;
  
  ${stylesFor.mobile} {
    width: 1.6rem;
    margin: 0;
  }
`;

const LineWrapMixin = css`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
`;

export const Name = styled.span<ColorPreferences & { username?: string }>`
  margin-bottom: 0.0625rem;
  max-width: 10.8rem;
  font: ${fonts.standard};
  color: ${({ dark }) => (dark ? "#fff" : "#000")};
  cursor: pointer;
  transition: color ${transition.show};
  ${({ username }) => username && LineWrapMixin};
`;

export const Username = styled.span<ColorPreferences & { name?: string }>`
  max-width: 10.8rem;
  font: ${fonts.description};
  word-wrap: break-word;
  color: ${({ dark }) =>
    dark ? "rgba(255, 255, 255, 0.7)" : "#000"};
  cursor: pointer;
  ${({ name }) => name && LineWrapMixin};
  transition: color ${transition.show};
`;

export const ProfileButtonBlock = styled.span<ScrollState>`
  max-width: calc(100% - 2rem);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ scrollHeight = 0 }) => (scrollHeight > 16 ? "4rem" : "5rem")};

  ${stylesFor.mobile} {
    padding-right: 0;
  }
`;

export const PopupContainer = styled(Blank)`
  padding: 0.625em 0;
  width: 13.25rem;
`;

export const Popover = styled(PopoverUI)`
  z-index: 10;
`;

export const AvatarContainer = styled.div`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.6875rem;
  cursor: pointer;

  img, div {
    border-radius: 50%;
  }

  ${stylesFor.mobile} {
    height: 3rem;
    width: 3rem;
    padding-right: 0;
  }
`;

export const AvatarImage = styled(Image)`
  ${stylesFor.mobile} {
    height: 3rem;
    width: 3rem;
  }
`;

export const LanguageButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
  height: 0.5rem;
`;

export const LanguageButton = styled.div<{ img: string; dark?: boolean }>`
  cursor: pointer;
  display: flex;
  width: 2rem;
  height: 1rem;
  mask-image: ${({ img }) => `url(${img})`};
  background-color: ${({ theme: { palette }, dark }) => (dark ? "#000" : palette.iconContrast)};
  opacity: ${({ dark }) => (dark ? 0.65 : 0.85)};
  transition: opacity ${transition.hover};

  :hover {
    opacity: 1;
  }
`;

export const LanguageMenuItem = styled.div`
  display: flex;
`;
