import React, { FC, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Page,
  BackgroundVideo,
  ButtonAndProjectsContainer,
  MainContainer,
  PlayButton,
  PlayText,
  PlayVideoContainer,
  ProjectBlock,
  ProjectImage,
  ProjectsContainer,
  Title,
  VideoContainer,
  WindowHeightContainer,
  OpenAppButton,
} from "./styled";

import { ExternalLink } from "components/styled/links/Link";
import { UsersCards } from "./UsersCards";

import { ROUTES } from "appConfig";
import { api } from "api/api";
import { enhance } from "./enhance";

import { openAppLink } from "utils/user";
import { getPortalConfigSelector } from "../../ducks/portalConfig";

import { MainBaseProps } from "./types";

import video from "video/animation.mp4";
import gif from "video/main.gif";

const MainBase: FC<MainBaseProps> = ({ lastProjects }) => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const { disabledComponents } = config?.settings || {};

  const renderLastProjects = useCallback(() => {
    if (lastProjects && lastProjects.length > 0) {
      return lastProjects.slice(0, 3).map((project) => (
        <ProjectBlock column key={project.name}>
          <ExternalLink href={`${ROUTES.MAP}${project.name}`} title={project.alias || project.name}>
            <ProjectImage src={project.preview || void 0} />
          </ExternalLink>
        </ProjectBlock>
      ));
    }
  }, [lastProjects]);

  const addSharedCategory = useCallback(() => {
    api.account.isAuth && window.localStorage.setItem("category", "shared");
  }, [api.account.isAuth, localStorage]);

  const removeSharedCategory = useCallback(() => {
    window.localStorage.getItem("category") === "shared" && window.localStorage.removeItem("category");
  }, [window.localStorage]);

  if (config === undefined || (disabledComponents?.main === true && window.location.pathname === ROUTES.MAIN)) {
    return null;
  }

  return (
    <Page>
      <WindowHeightContainer column>
        <MainContainer column>
          <Title>{t("geodataIsEasy")}</Title>
          <VideoContainer>
            <BackgroundVideo gif={gif} src={video} />
          </VideoContainer>
          <ButtonAndProjectsContainer column>
            <ExternalLink href={openAppLink()}>
              <OpenAppButton primary onClick={removeSharedCategory}>
                {api.account.isAuth ? t("openApp") : t("getStarted")}
              </OpenAppButton>
            </ExternalLink>
            {api.account.isAuth ? (
              <ProjectsContainer>{renderLastProjects()}</ProjectsContainer>
            ) : (
              <PlayVideoContainer to={ROUTES.ABOUT}>
                <PlayButton />
                <PlayText>{t("systemPresentation")}</PlayText>
              </PlayVideoContainer>
            )}
          </ButtonAndProjectsContainer>
        </MainContainer>
      </WindowHeightContainer>
      <UsersCards addSharedCategory={addSharedCategory} />
    </Page>
  );
};

export const Main = enhance(memo(MainBase));
