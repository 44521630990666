import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Location as RouteLocation } from "@reach/router";
import { Image } from "@evergis/ui";

import { Container, MenuIcon, HeaderLink, MenuContainer, ExternalLink } from "./styled";
import { HeaderLogo as Logo } from "components/Logo/HeaderLogo";
import { NavLink } from "./NavLink";
import { RightButtons } from "./RightButtons";

import { ROUTES } from "appConfig";

import { withScroll } from "hoc/withScroll";
import { withScreenSize } from "hoc/withScreenSize";
import { openAppLink } from "utils/user";
import { getPortalConfigSelector } from "../../ducks/portalConfig";
import { enhance } from "./enhance";
import { api } from "api/api";

import { HeaderProps } from "./types";

const HeaderComponent: FC<HeaderProps> = ({
  user: { first_name, last_name, username, roles, has_profile_photo, profile_photo },
  observerScroll,
  scrollHeight,
  isMobile,
  dark,
}) => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const { customLogo, disabledComponents } = config?.settings || {};
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    observerScroll(() => {
      setShowMenu(false);
    });
  }, []);

  const toggleMenu = useCallback(() => {
    if (isMobile) {
      setShowMenu(!showMenu);
    }
  }, [isMobile, showMenu]);

  const isDark = !!dark && (typeof window !== "object" || scrollHeight < window.innerHeight - 64);
  const isDarkOrShowMenu = isDark || showMenu ? 1 : 0;

  if (config === undefined) {
    return null;
  }

  if (disabledComponents?.main === true && window.location.pathname === ROUTES.MAIN) {
    window.location.href = api.account.isAuth ? ROUTES.MAP : ROUTES.SIGN_IN;

    return null;
  }

  return (
    <RouteLocation>
      {({ location }: { location: Location }) =>
        location && !location.pathname.includes("sign") ? (
          <Container dark={isDark} scrollHeight={scrollHeight}>
            {isMobile && (
              <MenuIcon dark={isDark || showMenu} kind={showMenu ? "close" : "menu_vertical"} onClick={toggleMenu} />
            )}
            <HeaderLink to={ROUTES.MAIN}>
              {customLogo ? (
                <>
                  <Image id="custom-logo" src={customLogo.src} width={customLogo.width} height={customLogo.height} />
                  {customLogo.preserveText && (
                    <Logo light={isDark} hideLogo offsetLeft={`${(customLogo.width ?? 0) - 35}px`} />
                  )}
                </>
              ) : (
                <Logo light={isDark} />
              )}
            </HeaderLink>
            <MenuContainer>
              {!disabledComponents?.main && (
                <NavLink dark={!!isDarkOrShowMenu} to={ROUTES.MAIN} onClick={toggleMenu}>
                  {t("main")}
                </NavLink>
              )}
              {!disabledComponents?.about && (
                <NavLink dark={!!isDarkOrShowMenu} to={ROUTES.ABOUT} onClick={toggleMenu}>
                  {t("about")}
                </NavLink>
              )}
              {!disabledComponents?.help && (
                <NavLink dark={!!isDarkOrShowMenu} to={ROUTES.HELP.subPaths.getStarted} onClick={toggleMenu}>
                  {t("help")}
                </NavLink>
              )}
              <ExternalLink dark={!!isDarkOrShowMenu} href={openAppLink()} onClick={toggleMenu}>
                {t("map")}
              </ExternalLink>
            </MenuContainer>
            <RightButtons
              observerScroll={observerScroll}
              first_name={first_name}
              last_name={last_name}
              username={username}
              roles={roles}
              scrollHeight={scrollHeight}
              has_profile_photo={has_profile_photo}
              profile_photo={profile_photo}
              isMobile={isMobile}
              isDark={isDark}
            />
          </Container>
        ) : null
      }
    </RouteLocation>
  );
};

export const Header: FC<Partial<HeaderProps>> = enhance(
  withScreenSize(withScroll(memo(HeaderComponent) as FC<Partial<HeaderProps>>)),
);
