import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { parse } from "query-string";
import { navigate } from "@reach/router";
import { IconButton } from "@evergis/ui";

import { HeaderWithLogo } from "components/Logo/HeaderWithLogo";
import { FormInputs } from "./FormInputs";
import { FormFooter } from "./FormFooter";
import { HeaderContainer, FormContainer } from "./styled";

import { ROUTES } from "../../appConfig";

import { checkLogin, signUp } from "api/user";
import { hasNextParam } from "../../utils/window";
import { getResponseError } from "../../utils/user";
import { ValidationError } from "../../api";
import { enhance } from "./enhance";

import { ISignUpProps } from "./types";
import { ValidationErrorPayload } from "../../api/types";
import { UserFormError } from "../../ducks/user";

/**
 * TODO: форма прыгает если появляется ошибка
 */
const SignUpComponent: FC<ISignUpProps> = ({
  values = { email: "", username: "", password1: "", password2: "" },
  touched,
  errors,
  saveEmailToState,
  handleChange,
  handleBlur,
}) => {
  const { email, username, password1 } = values;
  const [isUsernameUnique, setIsUsernameUnique] = useState<boolean | undefined>(undefined);
  const [isEmailUnique, setIsEmailUnique] = useState<boolean | undefined>(undefined);
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState<Partial<UserFormError>>({
    email: "",
    none_field_errors: "",
    other: "",
  });

  useEffect(() => {
    addEventListener("keydown", sendOnEnter);

    return () => {
      removeEventListener("keydown", sendOnEnter);
    };
  }, []);

  const send = useCallback(() => {
    checkLogin({ username })
      .then((isExist) => {
        if (!isExist) {
          setIsWaiting(true);
          setIsUsernameUnique(true);
          signUp({ email, username, password: password1 })
            .then(() => {
              saveEmailToState && saveEmailToState(email);
              navigate((parse(window.location.search).next as string) || ROUTES.SIGN_UP_SUCCESS);
            })
            .catch(async (checkError: ValidationError | Error) => {
              const newPayload = await getResponseError(checkError);
              const newError = { other: newPayload };

              setError(newError as ValidationErrorPayload<UserFormError>);
            })
            .finally(() => {
              setIsWaiting(false);
            });
        }
      })
      .catch((sendError) => console.error(sendError.message));
  }, [username, email, username, password1, saveEmailToState, window.location.search]);

  const sendOnEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        send();
      }
    },
    [send],
  );

  const goBack = useCallback(() => {
    if (typeof window === "object") {
      window.history.back();
    }
  }, [window.history]);

  return (
    <FormContainer>
      {hasNextParam() ? (
        <HeaderWithLogo />
      ) : (
        <HeaderContainer>
          <HeaderWithLogo />
          <span style={{ marginRight: "-0.5rem" }}>
            <IconButton kind={"close"} onClick={goBack} />
          </span>
        </HeaderContainer>
      )}
      <FormInputs
        values={values}
        errors={errors}
        touched={touched}
        isUsernameUnique={isUsernameUnique}
        setIsUsernameUnique={setIsUsernameUnique}
        isEmailUnique={isEmailUnique}
        setIsEmailUnique={setIsEmailUnique}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormFooter
        values={values}
        error={error}
        errors={errors}
        isUsernameUnique={isUsernameUnique}
        isEmailUnique={isEmailUnique}
        isWaiting={isWaiting}
        send={send}
      />
    </FormContainer>
  );
};

export const Form = enhance(memo(SignUpComponent));
