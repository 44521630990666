import { useCallback } from "react";

import { usePasswordHelpTextHook } from "./types";

export const usePasswordHelpText: usePasswordHelpTextHook = ({ errors, touched, isPass2Changed }) => {
  const getPassword1HelpText = useCallback(() => {
    if (errors?.password1 && touched?.password1) {
      return errors.password1;
    }

    return "";
  }, [errors, touched]);

  const getPassword2HelpText = useCallback(() => {
    if (isPass2Changed) {
      if (errors?.password2) {
        return errors.password2;
      }

      if (touched?.password1 && errors?.password2) {
        return "Пароли не совпадают, повторите ввод.";
      }
    }

    return "";
  }, [isPass2Changed, errors, touched]);

  return {
    getPassword1HelpText,
    getPassword2HelpText,
  };
};
