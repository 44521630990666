import styled from "styled-components";
import { Description as D } from "@evergis/ui";

export const Description = styled(D)`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:last-of-type {
    margin: 0;
  }
`;
