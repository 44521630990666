import { compose } from "redux";
import { connect } from "react-redux";
import { withFormik } from "formik";

import mapStateToProps from "./structured-selectors";
import { emailSet } from "../../ducks/user";
import { signUpValidationSchema as validationSchema } from "./validationSchema";
import { IFormValues, ISignUpProps } from "./types";

const mapDispatchToProps = {
  saveEmailToState: emailSet,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormikConnect = withFormik<ISignUpProps, IFormValues>({
  mapPropsToValues: () => ({
    email: "",
    username: "",
    password1: "",
    password2: "",
  }),
  validationSchema,
  validateOnChange: true,
  handleSubmit: () => null,
});

export const enhance = compose(withConnect, withFormikConnect);
