export const isEmpty = (obj: any): boolean => {
  const has = Object.prototype.hasOwnProperty;

  for (const key in obj) {
    if (has.call(obj, key)) {
      return false;
    }
  }

  return true;
};
