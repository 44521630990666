// NOTE: Move that to evergis/ui
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { Image as ImageUI } from "@evergis/ui";

import { ImageProps } from "./types";

const ImageComponent: FC<ImageProps> = ({ src: srcProp, fallback, noFetch, ...rest }) => {
  const [src, setSrc] = useState(srcProp);

  const updateSourceImage = useCallback(async (source: string) => {
    try {
      if (noFetch) {
        if (source) {
          setSrc(source);
        }
      } else {
        await fetch(source);
        setSrc(source);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }, [noFetch]);

  useEffect(() => {
    updateSourceImage(srcProp || fallback || "");
  }, [srcProp, updateSourceImage]);

  return <ImageUI {...rest} src={src} />;
};

export const Image = memo(ImageComponent);
