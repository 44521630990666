import React, { FC, memo } from "react";

import { Form } from "./Form";
import { SignInFooter } from "./Footer";

import {
  SignInLeftContainer,
  SignInLeftContent,
  SignInLeftDescription,
  SignInLeftTitle,
  SignInPageContainer,
  SignInRightContainer,
} from "./styled";

import { useLoginScreenSettings } from "../../hooks/useLoginScreenSettings";

const SignInComponent: FC = () => {
  const { hasContent, logo, title, description, backgroundImage, backgroundVideo, footer, overlay } =
    useLoginScreenSettings();

  return (
    <SignInPageContainer bgImg={backgroundImage} overlay={overlay}>
      {hasContent && (
        <SignInLeftContainer>
          {!!logo && <img src={logo} alt={title?.text || ""} />}
          {(!!title?.text || !!description?.text) && (
            <SignInLeftContent>
              {!!title?.text && (
                <SignInLeftTitle
                  fontColor={title?.fontColor}
                  fontFamily={title?.fontFamily}
                  dangerouslySetInnerHTML={{ __html: title?.text || "" }}
                />
              )}
              {!!description?.text && (
                <SignInLeftDescription
                  fontColor={description?.fontColor}
                  fontFamily={description?.fontFamily}
                  dangerouslySetInnerHTML={{ __html: description?.text || "" }}
                />
              )}
            </SignInLeftContent>
          )}
          <SignInFooter {...footer} />
        </SignInLeftContainer>
      )}
      <SignInRightContainer>
        <Form />
      </SignInRightContainer>
      {!!backgroundVideo && (
        <video className="background-video" autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}
      {!hasContent && <SignInFooter {...footer} />}
    </SignInPageContainer>
  );
};

export const SignIn: FC = memo(SignInComponent);
