import React, { FC, memo } from "react";
import { RaisedButton } from "@evergis/ui";
import { useTranslation } from "react-i18next";

import {
  Background,
  Container,
  ImageContainer,
  Description,
  ButtonAppContainer,
  Svg,
  BlueLine,
  First4,
  Zero,
  Second4,
  ToMapButton,
  Footer404,
} from "./styled";

import { ExternalLink } from "components/styled/links/Link";

import { ROUTES } from "../../appConfig";

export const NotFoundComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Background>
        <ImageContainer>
          <Svg>
            <BlueLine />
            <First4 />
            <Zero />
            <Second4 />
          </Svg>
        </ImageContainer>
        <Container>
          <Description>{t("pageNotFound")}</Description>
          <ButtonAppContainer>
            <ExternalLink href={ROUTES.MAIN}>
              <RaisedButton primary>{t("gotoMainPage")}</RaisedButton>
            </ExternalLink>
            <ExternalLink href={ROUTES.MAP}>
              <ToMapButton primary>{t("gotoApp")}</ToMapButton>
            </ExternalLink>
          </ButtonAppContainer>
        </Container>
      </Background>
      <Footer404 darkText />
    </>
  );
};

export const NotFound = memo(NotFoundComponent);
