import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { ConnectToWms as ConnectToWmsRu } from "./ru/ConnectToWms";
import { ConnectToWms as ConnectToWmsEn } from "./en/ConnectToWms";

const COMPONENTS: Record<string, Record<string, FC>> = {
  ru: {
    connectToWms: ConnectToWmsRu,
  },
  en: {
    connectToWms: ConnectToWmsEn,
  }
};

export const WmsQgis: FC = memo(() => {
  const { i18n: { language } } = useTranslation();

  const ConnectToWmsComponent = COMPONENTS[language].connectToWms;

  return (
    <>
      <ConnectToWmsComponent />
    </>
  );
});
