import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  && {
    margin-bottom: 0;
  }
`;
