import { Dispatch } from "redux";
import { UrlPath } from "@evergis/api";

import { api } from "../../api/api";
import { portalConfigSet } from "./actions";

export const fetchPortalConfig = () => async (dispatch: Dispatch) => {
  const config = await api.portalSettings.fetchPortalSettings({ urlPath: UrlPath.Portal });

  dispatch(portalConfigSet(config));
};
