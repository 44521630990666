import React, { FC, memo, useState } from "react";
import { Input, PasswordToggle } from "@evergis/ui";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { InputContainer } from "components/styled/forms";

import { usePasswordHelpText } from "../../hooks/usePasswordHelpText";
import { useSignUpFormInputs } from "./useSignUpFormInputs";

import { ISignUpProps } from "./types";

const isValidEmail = (value: string | undefined) => {
  const validator = Yup.string().email();
  let isEmail = true;

  try {
    validator.validateSync(value);
  } catch {
    isEmail = false;
  }

  return isEmail;
};

// eslint-disable-next-line max-lines-per-function
const FormInputsComponent: FC<ISignUpProps> = ({
  values = { email: "", password1: "", password2: "", username: "" },
  errors, touched, handleChange, handleBlur, isUsernameUnique, setIsUsernameUnique, setIsEmailUnique, isEmailUnique,
}) => {
  const { t } = useTranslation();
  const { email, password1, password2, username } = values;
  const [isPass1Visible, setIsPass1Visible] = useState(false);
  const [isPass2Visible, setIsPass2Visible] = useState(false);
  const [isPass2Changed, setIsPass2Changed] = useState(false);

  const {
    getPassword1HelpText, getPassword2HelpText,
  } = usePasswordHelpText({
    errors, touched, isPass2Changed,
  });

  const {
    checkUsername, hasLoginError, getEmailHelpText, getUsernameHelpText, checkEmail, hasEmailError,
  } = useSignUpFormInputs({
    username, errors, touched, isUsernameUnique, setIsUsernameUnique, email, isEmailUnique, setIsEmailUnique,
  });

  return (
    <>
      <InputContainer>
        <Input
          type="text"
          id="email"
          name="email"
          onChange={e => {
            handleChange && handleChange(e);
            isValidEmail(e.target.value) && checkEmail(e);
          }}
          value={email}
          label={"Email:"}
          help={getEmailHelpText()}
          width={"100%"}
          error={hasEmailError}
          required
          onBlur={handleBlur}
          autoComplete="email"
        />
      </InputContainer>
      <InputContainer>
        <Input
          type="text"
          id="username"
          name="username"
          onChange={e => {
            handleChange && handleChange(e);
            checkUsername(e);
          }}
          value={username}
          label={`${t("login")}:`}
          help={getUsernameHelpText()}
          width={"100%"}
          required
          onBlur={handleBlur}
          error={hasLoginError}
          success={isUsernameUnique && !!username}
          autoComplete="username"
        />
      </InputContainer>
      <InputContainer>
        <Input
          type={isPass1Visible ? "text" : "password"}
          id="password1"
          name="password1"
          onChange={handleChange}
          value={password1}
          label={`${t("password")}:`}
          help={getPassword1HelpText()}
          width={"100%"}
          onBlur={handleBlur}
          error={!!errors?.password1 && touched?.password1}
        >
          <PasswordToggle
            isSelected={isPass1Visible}
            onClick={() => setIsPass1Visible(!isPass1Visible)}
          />
        </Input>
      </InputContainer>
      <InputContainer>
        <Input
          type={isPass2Visible ? "text" : "password"}
          id="password2"
          name="password2"
          onChange={e => {
            handleChange && handleChange(e);
            setIsPass2Changed(true);
          }}
          value={password2}
          label={`${t("confirmPassword")}:`}
          width={"100%"}
          onBlur={handleBlur}
          error={!!errors?.password2 && isPass2Changed}
          help={getPassword2HelpText()}
        >
          <PasswordToggle
            isSelected={isPass2Visible}
            onClick={() => setIsPass2Visible(!isPass2Visible)}
          />
        </Input>
      </InputContainer>
    </>
  );
};

export const FormInputs = memo(FormInputsComponent);
