type Condition = string;
export type ScreenSizeType = "mobile" | "laptop" | "desktop";
type ScreenCondition = ({ width, height }: { width: number; height: number }) => boolean;

export enum Width {
  Desktop = 1280,
  Laptop = 1024,
  Tablet = 768,
  Mobile = 480,
}

export enum Orientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

const And = (...conditions: Condition[]) => conditions.join(" and ");
// const Or = (...conditions: Condition[]) => conditions.join(", ");

export const query = (...conditions: Condition[]): Condition => `@media ${conditions.join("")}`;

export const maxWidth = (width: Width): Condition => `(max-width: ${width}px)`;
export const minWidth = (width: Width): Condition => `(min-width: ${width}px)`;
export const orientation = (orient: Orientation): Condition => `(orientation: ${orient})`;

/**
 * used in ts
 */
export const screenConditions: Record<ScreenSizeType, ScreenCondition> = {
  mobile: ({ width }) => width <= Width.Tablet,
  laptop: ({ width }) => width > Width.Tablet && width <= Width.Laptop,
  desktop: ({ width }) => width > Width.Laptop && width <= Width.Desktop,
};

/**
 * used in css
 */
export const stylesFor: Record<ScreenSizeType, Condition> = {
  mobile: query(And(maxWidth(Width.Tablet), orientation(Orientation.Portrait))),
  laptop: query(And(maxWidth(Width.Laptop))),
  desktop: query(And(maxWidth(Width.Desktop), orientation(Orientation.Landscape))),
};
