import React, { FC, memo } from "react";
import { LinearProgress } from "@evergis/ui";
import { useTranslation } from "react-i18next";

import { CenteredContentPage } from "../styled/layout/CenteredContentPage";
import { PageProgressContainer, PageProgressTitle } from "./styled";

const PageProgressComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredContentPage>
      <PageProgressContainer>
        <PageProgressTitle>{t("pleaseWait")}...</PageProgressTitle>
        <LinearProgress />
      </PageProgressContainer>
    </CenteredContentPage>
  );
};

export const PageProgress = memo(PageProgressComponent);
