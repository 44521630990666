import React, { FC, memo } from "react";

import {
  Paragraph,
  MarkedList,
  Code,
  Title,
  SectionTitle,
} from "../../styled";

export const Operators: FC = memo(() => (
  <>
    <Title>Operators</Title>

    <SectionTitle>Comparison operators</SectionTitle>

    <MarkedList>
      <li><Code>==</Code></li>
      <li><Code>!=</Code></li>
      <li><Code>&gt;</Code></li>
      <li><Code>&gt;=</Code></li>
      <li><Code>&lt;</Code></li>
      <li><Code>&lt;=</Code></li>
    </MarkedList>

    <Paragraph>
      Comparison operators return a Boolean value comparing the left and right values.
    </Paragraph>

    <Code block>
      int_attr == 2<br />
      str_attr != &quot;String value&quot;<br />
      date_attr == #&quot;2020-01-01&quot;<br />
      double_attr &gt;= 1.5 &amp;&amp; double_attr &lt; 5<br />
    </Code>

    <SectionTitle>Starts with, ends with, contains</SectionTitle>

    <Paragraph>
      For text values, the operators <Code>==</Code> и <Code>!=</Code> support special syntax:
    </Paragraph>

    <MarkedList>
      <li><Code>attr == &quot;value%&quot;</Code>, <Code>attr != &quot;value%&quot;</Code> - the value starts (does not start) with the text &quot;value&quot;
      </li>
      <li><Code>attr == &quot;%value&quot;</Code>, <Code>attr != &quot;%value&quot;</Code> - the value ends (does not end) with the text &quot;value&quot;
      </li>
      <li><Code>attr == &quot;%value%&quot;</Code>, <Code>attr != &quot;%value%&quot;</Code> -
        the value contains (does not contain) the text &quot;value&quot;
      </li>
    </MarkedList>

    <SectionTitle>Arithmetic operations</SectionTitle>

    <MarkedList>
      <li><Code>+</Code> - addition of values. For string values-combining two values into one string.
      </li>
      <li><Code>-</Code> - subtraction</li>
      <li><Code>*</Code> - multiplication</li>
      <li><Code>/</Code> - division</li>
    </MarkedList>

    <SectionTitle>Boolean operations</SectionTitle>

    <MarkedList>
      <li><Code>||</Code> - OR</li>
      <li><Code>&amp;&amp;</Code> - AND</li>
    </MarkedList>
  </>
));
