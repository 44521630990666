import styled, { css } from "styled-components";
import { FlatButton, H1, H2, H4, IconButton, IconButtonButton, IconButtonInnerChild, transition } from "@evergis/ui";

import { FormContainer } from "../../components/styled/forms";
import { stylesFor } from "../../styles/queries";
import { CenteredContentPage } from "../../components/styled/layout/CenteredContentPage";
import { Flex } from "../../components/styled/layout/Flex";

const BgImgMixin = css<{ bgImg?: string }>`
  background: url(${({ bgImg }) => bgImg}) scroll 0 0 no-repeat;
  background-size: cover;
`;

const OverlayMixin = css<{ overlay?: string }>`
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ overlay }) => overlay};
  }
`;

const SignInPageLaptopMixin = css`
  && {
    flex-direction: column;
  }

  > .footer-image {
    display: block;
    visibility: visible;
  }
`;

export const SignInPageContainer = styled(CenteredContentPage)<{ bgImg?: string; overlay?: string }>`
  position: relative;
  justify-content: space-between;
  width: auto;
  padding: 0 5rem;
  overflow: hidden;

  ${({ bgImg }) => !!bgImg && BgImgMixin};
  ${({ overlay }) => !!overlay && OverlayMixin};

  .background-video {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  > .footer-image {
    z-index: 1;
    position: relative;
    display: none;
    visibility: hidden;
    margin-top: 2rem;
  }

  ${stylesFor.laptop} {
    ${SignInPageLaptopMixin};
  }

  ${stylesFor.mobile} {
    ${SignInPageLaptopMixin};
  }
`;

export const SignInFormContainer = styled(FormContainer)`
  justify-content: center;
  min-width: 22.5rem;
  padding: 2.5rem;
  background-color: ${({ theme: { palette } }) => palette.background};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.xSmall};

  ${stylesFor.mobile} {
    min-width: 19rem;
  }
`;

export const SignInTitle = styled(H1)`
  margin: 0;
  font-size: 2.25rem;
`;

export const SignInFormHeader = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const SignInSideContainer = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const SignInLeftLaptopMixin = css`
  padding-right: 0;
  padding-bottom: 3rem;
  align-items: center;

  .footer-image {
    display: none;
    visibility: hidden;
  }
`;

export const SignInLeftContainer = styled(SignInSideContainer)`
  flex-wrap: nowrap;
  justify-content: space-between;
  height: calc(100% - 10rem);
  padding: 5rem 2.5rem 5rem 0;

  .footer-image {
    margin-top: 3.5rem;
  }

  ${stylesFor.desktop} {
    height: auto;
  }

  ${stylesFor.laptop} {
    ${SignInLeftLaptopMixin};
  }

  ${stylesFor.mobile} {
    ${SignInLeftLaptopMixin};
  }
`;

export const SignInRightContainer = styled(SignInSideContainer)`
  align-items: center;
`;

export const SignInLeftContent = styled.div`
  max-width: 42.5rem;
`;

type TextProps = {
  fontColor?: string;
  fontFamily?: string;
};

const DEFAULT_FONT_FAMILIES = "'Roboto', Tahoma";

export const SignInLeftTitle = styled(H1)<TextProps>`
  margin: 3rem 0 0;
  font-size: 4.5rem;
  color: ${({ theme: { palette }, fontColor }) => fontColor || palette.textContrast};
  ${({ fontFamily }) => !!fontFamily && `font-family: ${fontFamily}, ${DEFAULT_FONT_FAMILIES};`}

  ${stylesFor.desktop} {
    font-size: 3.5rem;
  }

  ${stylesFor.laptop} {
    text-align: center;
    font-size: 3.5rem;
  }

  ${stylesFor.mobile} {
    text-align: center;
    font-size: 3.5rem;
  }
`;

export const SignInLeftDescription = styled(H2)<TextProps>`
  margin: 2rem 0 0;
  color: ${({ theme: { palette }, fontColor }) => fontColor || palette.textContrast};
  ${({ fontFamily }) => !!fontFamily && `font-family: ${fontFamily}, ${DEFAULT_FONT_FAMILIES};`}
  line-height: 2rem;
`;

export const NewMemberTitle = styled(H4)`
  ${stylesFor.mobile} {
    margin: 0 0.4rem;
  }
`;

export const SendConfirmEmailButton = styled(FlatButton)`
  margin-top: 0.5rem;
  text-transform: none;
  font-size: 0.75rem;
`;
SendConfirmEmailButton.defaultProps = {
  primary: true,
};

export const FooterButtons = styled(Flex)`
  align-items: center;
  height: 2rem;
  margin-top: 2rem;

  ${IconButtonButton} {
    opacity: 0.8;
    transition: ${transition.hover} opacity;

    :hover {
      opacity: 1;

      ${IconButtonInnerChild} {
        color: ${({ theme: { palette } }) => palette.textContrast};
      }
    }
  }

  ${IconButtonInnerChild} {
    color: ${({ theme: { palette } }) => palette.textContrast};
  }

  span[kind]:after {
    font-size: 1.25rem;
    color: ${({ theme: { palette } }) => palette.textContrast};
  }
`;

export const SocialButtons = styled(Flex)`
  align-items: center;
  height: inherit;

  a {
    width: 1.25rem;
    margin-right: 1.25rem;

    ${IconButtonButton} {
      span[kind] {
        width: 1.25rem;
      }
    }
  }
`;

export const LinkButton = styled(IconButton)`
  margin-top: 0.25rem;

  span[kind]:after {
    font-size: 1rem;
  }
`;

export const SocialDivider = styled.div`
  width: 0;
  height: 1.25rem;
  margin: 0.25rem 1.5rem 0 0.5rem;
  border-right: 1px ${({ theme: { palette } }) => palette.textContrast} solid;
  opacity: 0.28;
`;
