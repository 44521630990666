import { createStructuredSelector } from "reselect";

import { RootState } from "ducks/reducers";
import { getUserSocialErrorSelector, getUserSuccessSelector, UserState } from "ducks/user";

const structuredSelectors = createStructuredSelector<RootState, Pick<UserState, "success" | "socialError">>({
  success: getUserSuccessSelector,
  socialError: getUserSocialErrorSelector,
});

export default structuredSelectors;
