import { useCallback, useMemo } from "react";

import { checkLogin, checkEmail as checkEmailUnique } from "api/user";
import { useTranslation } from "react-i18next";
import { useValidValue } from "../../hooks/useValidValue/useValidValue";
import { useSignUpFormInputsHook } from "./types";
import { ServerErrorType } from "api/types";

// eslint-disable-next-line max-lines-per-function
export const useSignUpFormInputs: useSignUpFormInputsHook = ({
  username,
  errors,
  touched,
  isUsernameUnique,
  setIsUsernameUnique,
  email,
  isEmailUnique,
  setIsEmailUnique,
}) => {
  const { t } = useTranslation();
  const checkUsername = useValidValue(checkLogin, "username", ServerErrorType.InvalidUsername, setIsUsernameUnique);
  const checkEmail = useValidValue(checkEmailUnique, "email", ServerErrorType.InvalidEmail, setIsEmailUnique);

  const getEmailHelpText = useCallback(() => {
    if (errors?.email && touched?.email) {
      return errors.email;
    }

    return isEmailUnique === false ? t("emailNotUnique") : t("hereActivationLinkArrive");
  }, [isEmailUnique, errors, touched, t]);

  const getUsernameHelpText = useCallback(() => {
    if (isUsernameUnique) return "";
    if (isUsernameUnique === undefined) return t("onlyLatinLettersAndDigits");

    if (isUsernameUnique && errors?.username && touched?.username) {
      return errors.username;
    }

    return isUsernameUnique === false ? t("loginNotUnique") : t("invalidUsername");
  }, [isUsernameUnique, errors, touched, t]);

  const hasEmailError = useMemo(() => {
    return (!!errors?.email && touched?.email) || (!!email && !isEmailUnique && isEmailUnique !== undefined);
  }, [email, errors?.email, touched?.email, isEmailUnique]);

  const hasLoginError = useMemo(() => {
    return (
      (!!errors?.username && touched?.username) || (!!username && !isUsernameUnique && isUsernameUnique !== undefined)
    );
  }, [username, errors?.username, touched?.username, isUsernameUnique]);

  return {
    checkUsername,
    checkEmail,
    hasLoginError,
    hasEmailError,
    getEmailHelpText,
    getUsernameHelpText,
  };
};
