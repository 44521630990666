// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { Dispatch } from "redux";
import { closeNotification as closeNotificationAction, addNotification as addNotificationAction } from "./actions";
import { MessageType } from "./types";

export const mapServerMessages = (messages: any[]) => {
  const id = Math.random().toString(36);

  return messages.map(message => {
    return {
      id,
      success: message.tags === "success",
      error: message.tags === "error",
      title: message.message,
      description: message.description,
    };
  });
};

export const closeNotification = (messageId: string) => async (dispatch: Dispatch) => {
  dispatch(closeNotificationAction(messageId));
};

export const addNotification = (message: MessageType) => async (dispatch: Dispatch) => {
  dispatch(addNotificationAction(message));
};
