import React, { FC, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Image } from "@evergis/ui";

import { HeaderLink } from "components/Header/styled";
import { Logo } from "./Logo";
import { Container, Text } from "./styled";
import { Flex } from "../styled/layout/Flex";

import { ROUTES } from "appConfig";
import { stylesFor } from "styles/queries";

import { getPortalConfigSelector } from "../../ducks/portalConfig";

export const HeaderWithLogoComponent: FC = () => {
  const config = useSelector(getPortalConfigSelector);
  const { customLogo, disabledComponents } = config?.settings || {};

  const renderLogo = useCallback(() => {
    return config !== undefined ? (
      <>{customLogo ? <Image src={customLogo.src} width={customLogo.width} height={customLogo.height} /> : <Logo />}</>
    ) : null;
  }, [config, customLogo]);

  return (
    <Container>
      {!disabledComponents?.main ? (
        <>
          <HeaderLink to={ROUTES.MAIN}>{renderLogo()}</HeaderLink>
          {config !== undefined && (!customLogo || customLogo?.preserveText) && <Text>EverGIS</Text>}
        </>
      ) : (
        renderLogo()
      )}
    </Container>
  );
};

export const HeaderContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;

  ${stylesFor.mobile} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  ${stylesFor.laptop} {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;

export const HeaderWithLogo = memo(HeaderWithLogoComponent);
