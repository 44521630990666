import { compose } from "redux";
import { connect } from "react-redux";

import mapStateToProps from "./structured-selectors";
import { fetchUser, userReset, fetchPreview1 } from "../../ducks/user";

const mapDispatchToProps = {
  fetchUser,
  userReset,
  fetchPreview1,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose(withConnect);
