import React, { FC } from "react";

import { PageLayout } from "../components/PageLayout";
import { QueryLanguage } from "../../help/query-language";

export const QueryLanguagePage: FC = () => (
  <PageLayout>
    <QueryLanguage />
  </PageLayout>
);
