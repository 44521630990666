import styled from "styled-components";
import { Message as UIMessage, Image as UIImage } from "@evergis/ui";

import { stylesFor } from "../../styles/queries";
import { Video } from "../../components/Video/Video";

export const Image = styled(UIImage)`
  display: flex;
  flex-wrap: wrap;
  margin: auto;

  ${stylesFor.mobile} {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  ${stylesFor.laptop} {
    max-width: 100%;
  }
`;

export const ContentVideo = styled(Video)`
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem auto;
  width: 672px;
  height: 450px;
  background: #000;

  ${stylesFor.mobile} {
    width: auto;
    height: 12rem;
  }

  ${stylesFor.laptop} {
    width: auto;
    height: 20rem;
  }
`;

export const HelpTitle = styled.div`
  font-size: 2.25rem;
  font-weight: 300;
  opacity: 0.87;
  line-height: 2.5;
  letter-spacing: 0.15px;

  ${stylesFor.mobile} {
    line-height: 1.1;
  }
  ${stylesFor.laptop} {
    line-height: 2;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  opacity: 0.87;
`;

const List = styled.ul`
  list-style-type: none;
  line-height: 1.8;


  ${stylesFor.mobile} {
    padding-left: 1.4rem;
  }
`;

export const MarkedList = styled(List)`
  opacity: 0.8;

  li::before {
    content: "\\2022";
    color: #d8d8d8;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const OrderedList = styled.ol`
  li {
    margin-bottom: 1rem;
  }
`;

export const UnMarkedList = styled(List)``;

export const Paragraph = styled.p`
  opacity: 0.87;
  line-height: 1.5;
`;

export const BoldParagraph = styled.span`
  font-weight: 900;
  opacity: 1;
`;

export const ItalicType = styled.i`
  opacity: 0.54;
`;

export const Message = styled(UIMessage)`
  margin: auto 0 1rem;
  font-size: 0.75rem;

  div:first-child {
    font-size: 1rem;
    line-height: 2;
  }
`;

export const Container = styled.div`
  display: flex;
  padding-top: 5rem;
  min-height: calc(100vh - 14.75rem);

  
  ${stylesFor.mobile} {
    flex-direction: column
  }
`;

export const DescriptionTerms = styled.section`
  flex: 3;
  padding: 8rem 0 4.5rem 2.5rem;
  margin-right: 14%;
  min-height: calc(100vh - 14.75rem);

  ${stylesFor.mobile} {
    width: 100vw;
    box-sizing: border-box;
    margin: 0;
    padding: 1.4rem;
  }
  
  ${stylesFor.laptop} {
    padding: 2rem 2rem 4.5rem 2rem;
    margin-right: 0;
    box-sizing: border-box;
  }
`;

export const SectionTitle = styled.p`
  font-size: 1.25rem;
`;

export const Code = styled.div`
  display: ${({ block }: { block?: boolean }) => block ? "block" : "inline"};
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  line-height: 1.5;
`;

export const ImageTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: 2rem;
`;
