import React, { FC, memo } from "react";
import { RaisedButton } from "@evergis/ui";
import { useTranslation } from "react-i18next";

import { ExternalLink } from "components/styled/links/Link";

import { ROUTES } from "../../appConfig";
import { Content, ImageContainer, Wrapper, Container, Description, Title, Footer } from "./styled";

export const ForbiddenComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>
        <ImageContainer />
        <Container>
          <Title>{t("pageForbiddenTitle")}</Title>
          <Description>{t("pageForbiddenDescription")}</Description>
        </Container>
      </Content>
      <Footer>
        <ExternalLink href={ROUTES.MAIN}>
          <RaisedButton primary>{t("gotoMainPage")}</RaisedButton>
        </ExternalLink>
      </Footer>
    </Wrapper>
  );
};

export const Forbidden = memo(ForbiddenComponent);
