import { compose } from "redux";
import { connect } from "react-redux";

import { bindSocialNetwork } from "ducks/user";
import mapStateToProps from "./structured-selectors";

const mapDispatchToProps = {
  bindSocialNetwork,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose(withConnect);
