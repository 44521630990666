import React, { FC, memo } from "react";

import {
  HelpTitle,
  Paragraph,
  MarkedList,
  Code,
} from "../../styled";

export const ForWhat: FC = memo(() => (
  <>
    <HelpTitle>Язык атрибутивных запросов</HelpTitle>

    <Paragraph>Атрибутивные запросы в EverGIS используются для:</Paragraph>

    <MarkedList>
      <li>
        написания условий для фильтрации объектов в слоях, при запросе данных, при
        выборе применяемого стиля
      </li>
      <li>
        для динамического расчёта значений в классификаторах
      </li>
      <li>
        для расчёта значений атрибутов на основе других атрибутов в операциях
        массового редактирования
      </li>
    </MarkedList>

    <Paragraph>
      Запросы применяются к слоям с гео-данными и при исполнении используют системные
      названия атрибутов.
    </Paragraph>

    <Paragraph>
      Например, если имеется слой с атрибутами <Code>str_attr</Code> (текст), <Code>int_attr</Code>
      (целочисленный), для такого слоя можно составить запросы:
    </Paragraph>

    <MarkedList>
      <li><Code>str_attr is {"{null}"}</Code> - вернет значение <Code>true</Code> если
        значение атрибута у объекта
        не задано
      </li>
      <li><Code>str_attr + str_attr</Code> - вернет текстовое значение, в котором значение атрибута
        повторяется дважды
      </li>
      <li><Code>int_attr &gt; 10 &amp;&amp; int_attr &lt; 20</Code> - вернет <Code>true</Code> если значение атрибута
        находится в пределах от 10 до 20
      </li>
      <li><Code>int_attr * 2 + 5</Code> - вернет целое число - результат выполнения выражения</li>
    </MarkedList>
  </>
));
