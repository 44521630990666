import { createStructuredSelector } from "reselect";

import { RootState } from "../../ducks/reducers";
import { getUserDetailSelector, getUserErrorSelector, UserState } from "../../ducks/user";

const structuredSelectors = createStructuredSelector<RootState, Pick<UserState, "error"> & { detail?: string }>({
  error: getUserErrorSelector,
  detail: getUserDetailSelector,
});

export default structuredSelectors;
