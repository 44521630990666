import React, { FC, memo } from "react";

import { HelpTitle, MarkedList, OrderedList, Image, ImageTitle, Paragraph } from "../../styled";

import fig1 from "./media/fig-1.png";
import fig2 from "./media/fig-2.png";
import fig3 from "./media/fig-3.png";

export const ConnectToWms: FC = memo(() => (
  <>
    <HelpTitle>Connecting to WMS in QGIS</HelpTitle>

    <OrderedList>
      <li>
        In the browser, select the WMS/WMTS menu item
      </li>
      <li>
        In the context menu of the selected item, select "Create connection" (Fig. 1)
      </li>
      <li>
        In the window that appears, "Create a new WMS/WMTS connection" (Fig. 2) fill in:
        <MarkedList style={{ marginTop: "1rem" }}>
          <li>
            Name (1) – display name
          </li>
          <li>
            Address (2) – the url of the WMS server (for example https://ever.2gis.ru.ru/sp/wms)
          </li>
          <li>
            In the "Basic" tab (3) of the authentication block, fill in the fields: username (4) and password (5) from the EverGIS system
          </li>
        </MarkedList>
      </li>
      <li>
        Add the necessary layer to the QGIS map (Fig. 3)
      </li>
    </OrderedList>
    <Paragraph>
      <Image src={fig1} />
      <ImageTitle>Fig. 1</ImageTitle>
    </Paragraph>
    <Paragraph>
      <Image src={fig2} />
      <ImageTitle>Fig. 2</ImageTitle>
    </Paragraph>
    <Paragraph>
      <Image src={fig3} />
      <ImageTitle>Fig. 3</ImageTitle>
    </Paragraph>
  </>
));
