import { createStructuredSelector } from "reselect";

import { RootState } from "ducks/reducers";
import {
  getFirstNameSelector, getLastNameSelector, getLastProjectsSelector, getUsedProjectsSelector,
  getUserLoadingSelector, getUsernameSelector, UserState,
} from "ducks/user";

const structuredSelectors = createStructuredSelector<RootState, UserState>({
  username: getUsernameSelector,
  first_name: getFirstNameSelector,
  last_name: getLastNameSelector,
  lastProjects: getLastProjectsSelector,
  used_projects: getUsedProjectsSelector,
  loading: getUserLoadingSelector,
});

export default structuredSelectors;
