import { createSelector } from "reselect";

import { RootState } from "../reducers";

export const getUserSelector = (state: RootState) => state.user;

export const getEmailSelector = createSelector(getUserSelector, user => user.email);

export const getUsernameSelector = createSelector(getUserSelector, user => user.username);

export const getFirstNameSelector = createSelector(getUserSelector, user => user.first_name);

export const getLastNameSelector = createSelector(getUserSelector, user => user.last_name);

export const getLastProjectsSelector = createSelector(getUserSelector, user => user.lastProjects);

export const getUsedProjectsSelector = createSelector(getUserSelector, user => user.used_projects);

export const getUserLoadingSelector = createSelector(getUserSelector, user => user.loading || false);

export const getCompanySelector = createSelector(getUserSelector, user => user.company);

export const getPositionSelector = createSelector(getUserSelector, user => user.position);

export const getLocationSelector = createSelector(getUserSelector, user => user.location);

export const hasProfilePhotoSelector = createSelector(getUserSelector, user => user.has_profile_photo);

export const getProfilePhotoSelector = createSelector(getUserSelector, user => user.profile_photo);

export const getIsSubscribedSelector = createSelector(getUserSelector, user => user.is_subscribed);

export const getIsOpenLastProjectSelector = createSelector(getUserSelector, user => user.is_open_last_project);

export const isUserRequested = createSelector(getUserSelector, user => user.is_user_requested || false);

export const getUserErrorSelector = createSelector(getUserSelector, user => user.error);

export const getUserSocialSelector = createSelector(getUserSelector, user => user.social);

export const getUserSocialErrorSelector = createSelector(getUserSelector, user => user.socialError);

export const getEmailNotConfirmed = createSelector(getUserSelector, user => user.emailNotConfirmed);

export const getUserDetailSelector = createSelector(getUserSelector, user => user.detail);

export const isPasswordSetSelector = createSelector(getUserSelector, user => user.is_password_set);

export const getUserSuccessSelector = createSelector(getUserSelector, user => user.success);

export const getUserLimitsSelector = createSelector(getUserSelector, user => user.limits);

export const getRolesSelector = createSelector(getUserSelector, user => user.roles);