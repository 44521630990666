import styled from "styled-components";
import { IconButton, IFlexProps } from "@evergis/ui";

import { Flex } from "../styled/layout/Flex";
import { ExternalLink } from "components/styled/links/Link";
import { ContentContainer as Container } from "components/styled/layout/ContentContainer";
import { stylesFor } from "../../styles/queries";
import { TextProps } from "./types";

export const Background = styled(Flex)`
  background: #002537;
  justify-content: center;
  width: calc(100% - 24.25rem);
  min-height: 5.625rem;
  padding: 2rem 12.125rem;

  @media (max-width: 1440px) {
    padding: 2rem;
    width: calc(100% - 4rem);
  }

  ${stylesFor.mobile} {
    padding: 1.5rem 1rem;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const LinksContainer = styled(Flex)`
  margin-top: 0.75rem;

  @media (max-width: 1440px) {
    flex-direction: column;
    margin-left: 1.5rem;
  }
`;

export const InfoContainer = styled(Flex)<IFlexProps>`
  margin-top: 0.75rem;
  min-width: 15.0625rem;
  & > *:not(:first-child) {
    margin-top: 0.3125rem;
  }

  & > *:last-child {
    margin-top: 0.5rem;
  }
`;

export const Text = styled.div<TextProps>`
  font: ${({ theme: { fonts } }) => fonts.description};
  color: ${({ dark }: TextProps) => (dark ? "rgba(0, 0, 0, 0.65)" : "#fff")};
`;

export const WhiteLink = styled(ExternalLink)`
  font: ${({ theme: { fonts } }) => fonts.description};
  &&,
  &&&:visited,
  &&:active,
  &&:hover {
    color: ${({ dark }: TextProps) => (dark ? "rgba(0, 0, 0, 0.65)" : "#fff")};
  }

  ${LinksContainer} > &:not(:first-of-type) {
    margin-left: 1.5rem;

    @media (max-width: 1440px) {
      margin: 0.3125rem 0 0;
    }
  }
`;

export const Contacts = styled(Text)`
  opacity: 0.7;
`;

export const IconsContainer = styled(Flex)`
  margin: 1rem 0 0 -0.4rem;

  & * {
    margin: 0;
  }
`;

export const LinkButtons = styled(IconButton)`
  height: 1rem;
  opacity: 0.85;
`;

export const FooterContainer = styled(Container)`
  ${stylesFor.mobile} {
    flex-direction: column;
    align-items: start;
  }
`;
