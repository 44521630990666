import { SocAuthNetwork } from "@evergis/api/dist/Api";

import { UserState } from "ducks/user";

export enum NetworkProvider {
  vk = "Vkontakte",
  google = "Google",
  facebook = "Facebook",
}

export type SocialProps = { userId: string, providerName: NetworkProvider };

export type SocialNetworksProps = {
  social?: SocialProps[];
  socialError?: UserState["socialError"];
  isPasswordSet?: boolean;
  onUnbindFinish?: VoidFunction;
  unbindSocialNetwork?: (network: SocAuthNetwork) => void;
};
