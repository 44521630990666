import { SocAuthNetwork } from "@evergis/api/dist/Api";

export type Methods = "GET" | "POST" | "PUT" | "DELETE";
export type FetchOptions = Omit<RequestInit, "method">;
export type FetcherOptions = Omit<FetchOptions, "body">;

type ValidationErrorPayloadBase = {
  non_field_errors: string;
};

export type ValidationErrorPayload<Fields extends object = object> = {
  [key in keyof Fields]: string
} &
  ValidationErrorPayloadBase;

export type User = {
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  is_subscribed?: boolean;
  is_open_last_project?: boolean;
  position?: string;
  company?: string;
  location?: string;
  has_profile_photo?: boolean;
  is_user_requested?: boolean;
  is_password_set?: boolean;
  last_projects?: ILastProject[];
};

interface ILastProject {
  name?: string;
  updated?: string;
  preview?: string;
  alias?: string;
}

export type EditUser = {
  first_name?: string;
  last_name?: string;
  is_subscribed?: boolean;
  is_open_last_project?: boolean;
  position?: string;
  company?: string;
  location?: string;
  profile_photo?: string;
  has_profile_photo?: boolean;
};

export interface IFile extends Blob {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export type SocialElements = Record<SocAuthNetwork, string>;

export type List<Item> = {
  count: Nullable<number>;
  next: Nullable<string>;
  previous: Nullable<string>;
  results: Item[];
}

export type UsedProjectInfo = {
  name:string;
  updated:string;
}

export type CheckLogin = {
  message: string;
}

export type RestorePassword = {
  password1: string;
  password2: string;
  uid: string;
  token: string;
}

export type ChangeEmail = {
  newEmail: string;
  password: string;
}

export type SetEmailAndPassword = {
  email: string;
  password: string;
}

export type SetPassword = {
  new_password1: string,
  new_password2: string
}

export type ILimits = {
  allowedServiceTypes: string[];
  currentProjectCount: number;
  currentServiceCount: number;
  currentTableCount: number;
  maxFeaturesInOneTable: number;
  maxProjectsCount: number;
  maxServicesCount: number;
  maxTablesCount: number;
}

export enum ServerErrorType {
  ValidationError = "ValidationError",
  RefreshTokenExpired = "RefreshTokenExpired",
  RefreshTokenInvalid = "RefreshTokenInvalid",
  TokenInvalid = "TokenInvalid",
  SetUserPasswordFailed = "SetUserPasswordFailed",
  SetUserRoleFailed = "SetUserRoleFailed",
  UpdateUserFailed = "UpdateUserFailed",
  RemoveUserFailed = "RemoveUserFailed",
  CreateRoleFailed = "CreateRoleFailed",
  UpdateRoleFailed = "UpdateRoleFailed",
  RemoveUserRoleFailed = "RemoveUserRoleFailed",
  ChangeActiveStateFailed = "ChangeActiveStateFailed",
  RemoveRoleFailed = "RemoveRoleFailed",
  UserNotFound = "UserNotFound",
  RoleNotFound = "RoleNotFound",
  EmailNotConfirmed = "EmailNotConfirmed",
  DuplicateEmailError = "DuplicateEmailError",
  InvalidPassword = "InvalidPassword",
  RoleExists = "RoleExists",
  UserExists = "UserExists",
  UserLockedError = "UserLockedError",
  InvalidEmail = "InvalidEmail",
  InvalidUsername = "InvalidUsername",
  NotSpecified = "NotSpecified",
  EmailNotChanged = "EmailNotChanged",
}
