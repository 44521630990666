import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getPortalConfigSelector, PortalConfiguration } from "../../ducks/portalConfig";

type useLoginFormSettingsType = PortalConfiguration["settings"]["loginForm"] &
  Pick<
    PortalConfiguration["settings"],
    "withPortal" | "allowRegistration" | "allowSNS" | "allowRecovery" | "showTermsOfService" | "disabledComponents"
  >;

export const useLoginFormSettings = (): useLoginFormSettingsType => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const { loginForm, withPortal, allowRegistration, allowSNS, allowRecovery, showTermsOfService, disabledComponents } =
    (config?.settings as PortalConfiguration["settings"]) || {};
  const { title, login, password, terms, button, remember, firstTime, signUp } = loginForm || {};

  return {
    title: title || t("loginTitle"),
    login: login || t("login"),
    password: password || t("password"),
    terms: {
      start: terms?.start || t("byClickingSingIn"),
      link: terms?.link || t("termsOfService"),
      end: terms?.end || t("andProcessingOfPersonalData"),
    },
    button: button || t("signIn"),
    remember: remember || t("forgotLoginPassword"),
    firstTime: firstTime || t("firstTime"),
    signUp: signUp || t("signUp"),
    withPortal,
    allowRegistration,
    allowSNS,
    allowRecovery,
    showTermsOfService,
    disabledComponents,
  };
};
