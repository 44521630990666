import React, { FC, memo, useMemo } from "react";
import { Flex } from "@evergis/ui";
import { useTranslation } from "react-i18next";

import { Link, ExternalLink } from "../../components/styled/links/Link";

import {
  Container,
  MoreCardsTitle,
  Subtitle,
  ProjectName,
  ProjectTitle,
  ProjectDescription,
  CardCreator,
  Image,
  UserCardsTitle,
  UserCardsProjectsContainer,
  UserCardsProjectBlock,
} from "./styled";

import { ROUTES } from "appConfig";

import { api } from "api/api";

import { UserCardsProps } from "./types";

import map1 from "./media/map1.png";
import map2 from "./media/map2.png";
import map3 from "./media/map3.png";

const UserCardsComponent: FC<UserCardsProps> = ({ addSharedCategory }) => {
  const { t } = useTranslation();

  const projectMap = useMemo(() => ([
    {
      src: map1,
      title: t("cities"),
      descr: t("citiesCardDescription"),
      creator: "Everpoint",
      link: "/shared/everpoint.wi624ln73qai",
    },
    {
      src: map2,
      title: t("moscowInfrastructure"),
      descr: t("moscowInfrastructreCardDescription"),
      creator: "Everpoint",
      link: "/shared/everpoint.xw17wewx3a5v",
    },
    {
      src: map3,
      title: t("transportProblems"),
      descr: t("transportProblemsCardDescription"),
      creator: "Everpoint",
      link: "/shared/everpoint.emvl50p4gi49",
    },
  ]), [t]);

  return (
    <Container column>
      <UserCardsTitle>{t("ourUsersProjects")}</UserCardsTitle>
      <Flex>
        <MoreCardsTitle>
          <Subtitle>{t("moreMaps")} —&nbsp;</Subtitle>
          {(api.account.isAuth && (
            <ExternalLink href={ROUTES.MAP} onClick={addSharedCategory}>
              {t("inCatalog")}
            </ExternalLink>
          )) || <Link to={ROUTES.SIGN_IN}>{t("inCatalog")}</Link>}
        </MoreCardsTitle>
      </Flex>
      <UserCardsProjectsContainer>
        {projectMap.map(project => (
          <UserCardsProjectBlock key={project.title}>
            <ExternalLink href={project.link} target={"_blank"}>
              <Image src={project.src} />
              <ProjectName column>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.descr}</ProjectDescription>
                <CardCreator>{t("createdBy")}: {project.creator}</CardCreator>
              </ProjectName>
            </ExternalLink>
          </UserCardsProjectBlock>
        ))}
      </UserCardsProjectsContainer>
    </Container>
  );
};

export const UsersCards = memo(UserCardsComponent);
