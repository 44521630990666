import { Flex } from "@evergis/ui";
import styled from "styled-components";

export const NotificationContainer = styled(Flex)`
  position: fixed;
  display: inline-block;
  z-index: 15;
  bottom: 0;
  right: 1rem;
  max-height: 100%;
  height: 100%;
  width: 26rem;
  align-items: flex-end;
  overflow: visible;
  pointer-events: none;
`;
