import { compose } from "redux";
import { connect } from "react-redux";

import { socAuthCallback } from "ducks/user";
import mapStateToProps from "./structured-selectors";

const mapDispatchToProps = {
  socAuthCallback,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose(withConnect);
