import { Flex } from "@evergis/ui";
import styled from "styled-components";
import desc from "./desc.png";

export const Wrapper = styled(Flex)`
  width: 100%;
  height: 100vh;
  flex-direction: column;
`;

export const Content = styled(Flex)`
  margin: auto;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 17.5rem;
  height: 17.5rem;
  background: url("${desc}") no-repeat 100% 100%;
`;

export const Container = styled.div`
  margin-left: 3.5rem;
  max-width: 31.875rem;
`;

export const Title = styled.div`
  font-size: 1.5rem;
`;

export const Description = styled.div`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

export const Footer = styled.div`
  margin: auto auto 1.5rem;
`;
