import React, { FC, memo } from "react";
import { withTheme } from "styled-components";

import { LogoSvg, Path, Polygon } from "./styled";
import { LogoProps } from "./types";

const LogoComponent: FC<LogoProps> = ({ mono, width }) => (
  <LogoSvg mono={mono} width={width}>
    <g>
      <Polygon />
      <Path />
    </g>
  </LogoSvg>
);

export const Logo = withTheme(memo(LogoComponent) as never);
