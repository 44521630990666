import React, { CSSProperties, FC, memo } from "react";
import styled from "styled-components";
import { Message } from "@evergis/ui";

interface IMessageProps {
  textLeft?: boolean;
  textRight?: boolean;
  textCenter?: boolean;
  style?: CSSProperties;
}

const FullWidthMessage = styled(Message)`
  width: calc(100% - 2rem);
  && {
    margin: 1rem 0 0;
  }

  p, span, div {
    color: inherit;
  }
`;

export const ErrorMessage: FC<IMessageProps> = memo(({ children, ...props }) => (
  <FullWidthMessage error {...props}>
    {children}
  </FullWidthMessage>
));
