import React, { FC, memo, useEffect } from "react";
import { navigate } from "@reach/router";

import { PageProgress } from "components/PageProgress";
import { CenteredContentPage } from "components/styled/layout/CenteredContentPage";
import { ErrorMessage, FormContainer } from "components/styled/forms";
import { ROUTES } from "appConfig";
import { SocBindCallbackScreenProps } from "./types";
import { NetworkProvider } from "../profile/SocialNetworks/types";
import { enhance } from "./enhance";

const SocBindCallbackScreenComponent: FC<SocBindCallbackScreenProps> = ({
  social,
  network,
  socialError,
  bindSocialNetwork,
}) => {
  useEffect(() => {
    if (network) {
      bindSocialNetwork();
    }
  }, []);

  useEffect(() => {
    if (social?.some(item => item.providerName === NetworkProvider[network])) {
      navigate(ROUTES.PROFILE);
    }
  }, [social, network]);

  return (
    <>
      {socialError ? (
        <CenteredContentPage>
          <FormContainer>
            <ErrorMessage>{socialError}</ErrorMessage>
          </FormContainer>
        </CenteredContentPage>
      ) : (
        <PageProgress />
      )}
    </>
  );
};

export const SocBindCallbackScreen = enhance(memo(SocBindCallbackScreenComponent));
