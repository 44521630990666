import { useMemo } from "react";
import { debounce } from "@evergis/sgis/es/utils/utils";

export const useDebouncedCallback = <CallbackFn extends () => void>(interval: number): ((cb: CallbackFn) => void) => {
  return useMemo(
    () =>
      debounce((cb: CallbackFn) => {
        cb();
      }, interval),
    [],
  );
};
