import { compose } from "redux";
import { connect } from "react-redux";

import mapStateToProps from "./structured-selectors";
import { getLastProjects } from "../../ducks/user";
import { MainBaseProps } from "./types";

export const mapDispatchToProps = {
  getLastProjects,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose<MainBaseProps>(withConnect);
