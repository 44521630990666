const key404 = Math.random().toString(36);

export const is404Page = (app: string): boolean => app.includes(key404);

export const qs = (query: string): Record<string, string> =>
  query
    .slice(1)
    .split("&")
    .map(qsPart => qsPart.split("="))
    .reduce((pairs, [key, value]) => {
      if (!key || typeof value === "undefined") return pairs;

      return {
        ...pairs,
        [key]: value,
      };
    }, {});
