import React, { FC, memo } from "react";
import { withTheme } from "styled-components";

import { Evergis, HeaderLogoSvg, Logo } from "./styled";
import { HeaderLogoProps } from "./types";

const HeaderLogoComponent: FC<HeaderLogoProps> = ({ light, hideLogo, ...rest }) => {
  return (
    <HeaderLogoSvg {...rest}>
      <Logo light={light} hideLogo={hideLogo} />
      <Evergis light={light} />
    </HeaderLogoSvg>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HeaderLogo = withTheme<any>(memo(HeaderLogoComponent)) as FC<HeaderLogoProps>;
