import styled from "styled-components";
import { Footer } from "../Footer/Footer";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const Footer404 = styled(Footer)`
  background: transparent;
  position: absolute;
  margin-top: -9.625rem;
`;
