import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: 1rem;
  }
`;
