import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Flex, WaitingButton, FlatButton, H4, Divider, Description } from "@evergis/ui";

import { Link, ExternalLink } from "components/styled/links/Link";
import { ButtonsContainer, ErrorMessage } from "components/styled/forms";
import { Desc } from "./styled";

import { ROUTES } from "../../appConfig";
import { api } from "../../api/api";

import { isEmpty } from "../../utils/object";
import { getPortalConfigSelector } from "../../ducks/portalConfig";

import { IFormFooterProps } from "./types";

const FormFooterComponent: FC<IFormFooterProps> = ({
  values = { email: "", username: "", password1: "", password2: "" },
  error,
  errors,
  isUsernameUnique,
  isEmailUnique,
  isWaiting,
  send,
}) => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const { allowSNS } = config?.settings || {};
  const { email, username, password1, password2 } = values;

  if (!config) {
    return null;
  }

  return (
    <>
      <Desc>
        {t("byClickingRegisterButton")}
        <br />
        <Link description to={ROUTES.MAIN}>
          {" "}
          {t("termsOfServiceAndPrivacyPolicy")}
        </Link>
      </Desc>
      <ButtonsContainer style={{ marginBottom: "1rem" }}>
        <WaitingButton
          isWaiting={isWaiting}
          primary
          disabled={
            !isEmpty(errors) ||
            !isUsernameUnique ||
            !isEmailUnique ||
            username === "" ||
            email === "" ||
            password1 === "" ||
            password2 === ""
          }
          onClick={send}
        >
          {t("register")}
        </WaitingButton>
        {(allowSNS === undefined || allowSNS) && (
          <Flex>
            <ExternalLink href={api.external.login("google")}>
              <IconButton kind={"social_google_simple"} />
            </ExternalLink>
            {/* <ExternalLink href={api.external.login("facebook")}>
              <IconButton kind={"social_fb_simple"} />
            </ExternalLink>*/}
            <ExternalLink href={api.external.login("vk")}>
              <IconButton kind={"social_vk_simple"} />
            </ExternalLink>
          </Flex>
        )}
      </ButtonsContainer>
      {((error.email && error.email[0]) || error.none_field_errors || error.other) && (
        <ErrorMessage>
          <Description style={{ margin: 0 }}>
            {error.email || error.none_field_errors || error.other}
            {error.email && error.email[0] && (
              <Link to={ROUTES.ACCESS_RECOVERY}>
                <Description style={{ marginBottom: 0 }}> {t("forgotLoginPassword")}</Description>
              </Link>
            )}
          </Description>
        </ErrorMessage>
      )}
      <Divider style={{ margin: "1rem 0" }} />
      <H4>{t("alreadyEvergisUser")}</H4>
      <Link description to={ROUTES.SIGN_IN}>
        <FlatButton primary>{t("goSignIn")}</FlatButton>
      </Link>
    </>
  );
};

export const FormFooter = memo(FormFooterComponent);
