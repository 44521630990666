import styled from "styled-components";
import { Flex } from "@evergis/ui";

export const ContentContainer = styled(Flex)`
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 71rem;
  @media (max-width: 1440px) {
    max-width: 61.25rem;
  }
`;
