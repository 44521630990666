import React, { FC } from "react";
import { compose } from "redux";
import { Redirect } from "@reach/router";

import { withUser } from "./withUser";
import { User } from "api/types";

export type RouteProps = {
  redirectTo: string;
  path: string;
  user?: User;
  condition?: (user: User) => boolean;
};

export const PrivateRoute = (EnhancedComponent: FC) => {
  return class extends React.Component<RouteProps> {
    static defaultProps = {
      condition: (user: User) => !!user.username,
    };

    render() {
      const { redirectTo, user, condition } = this.props;

      return condition && condition(user as User) ? (
        <EnhancedComponent />
      ) : (
        (user as User).is_user_requested && <Redirect to={redirectTo} />
      );
    }
  };
};

export const withAuth = compose(withUser, PrivateRoute);
