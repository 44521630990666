import { Dispatch, SetStateAction, useCallback } from "react";
import { ServerErrorType } from "../../api/types";
import { useDebouncedCallback } from "../useDebounceCallback";

export function useValidValue<T extends Record<string, string>>(
  checkValue: (payload: T) => Promise<boolean>,
  field: keyof T,
  errorType: ServerErrorType,
  setResponse?: Dispatch<SetStateAction<boolean | null>>,
) {
  const debounce = useDebouncedCallback(500);

  return useCallback(
    (e) => {
      if (!e.target.value) return;

      debounce(async () => {
        try {
          const response = await checkValue({ [field]: e.target.value } as T);

          setResponse?.(!response);
        } catch (error) {
          const serverMessage = await error.response.json();

          setResponse?.(serverMessage.ErrorType === errorType ? null : false);
        }
      });
    },
    [debounce, checkValue, setResponse],
  );
}
