import React, { FC, memo } from "react";

import {
  Paragraph,
  MarkedList,
  Code,
  Title,
  SectionTitle,
} from "../../styled";

export const DataTypes: FC = memo(() => (
  <>
    <Title>Types of values</Title>
    <SectionTitle>Integers</SectionTitle>

    <Paragraph>
      Positive or negative integer values that fit in 64 bits:
    </Paragraph>

    <Code block>
      0<br />
      5<br />
      -100<br />
      123456789
    </Code>

    <SectionTitle>Fractional values</SectionTitle>

    <Paragraph>
      Positive and negative fractional values separated by &quot;.&quot;. Comma separation is not supported.
    </Paragraph>

    <Code block>
      0.0<br />
      3.1415<br />
      -20.9
    </Code>

    <SectionTitle>Text values</SectionTitle>

    <Paragraph>
      Text values are framed with single or double quotes. If it’s necessary to enter a value with quotation marks, put a &quot;&quot; sign before the quotation mark.
    </Paragraph>

    <Code block>
      &quot;&quot;<br />
      &quot;String value&quot;<br />
      &apos;'Also a string value with double &quot;quotes&apos;<br />
      &quot;Double quotes \&quot; framed by double quotes&quot;<br />
    </Code>

    <SectionTitle>Date and time</SectionTitle>

    <Paragraph>
      Values of the date and time type are represented by a text value preceded by a &quot;#&quot; sign. The values are written in the YYYY-mm-DD HH:MM:SS format.
    </Paragraph>

    <Code block>
      #&quot;2019-10-08&quot;<br />
      #&apos;2000-01-01 00:00:01&apos;
    </Code>

    <SectionTitle>Time period</SectionTitle>

    <Paragraph>
      The time period is used in combination with dates and time, and allows you to express expressions like
      &quot;DATE +/- PERIOD&quot;. The period value is set by the symbol
      &quot;#&quot;, followed by an integer and a dimension pointer.
    </Paragraph>

    <Code block>
      #1s (one second)<br />
      #5h (five hours)<br />
      #365d (365 days)
    </Code>

    <SectionTitle>Supported dimensions:</SectionTitle>

    <MarkedList>
      <li><Code>ms</Code> - milliseconds</li>
      <li><Code>s</Code> - seconds</li>
      <li><Code>m</Code> - minutes</li>
      <li><Code>h</Code> - hours</li>
      <li><Code>d</Code> - days</li>
      <li><Code>mth</Code> - months</li>
      <li><Code>y</Code> - years</li>
    </MarkedList>

    <SectionTitle>Special values</SectionTitle>

    <Paragraph>
      Special values are framed by curly brackets: {"{ }"}.
    </Paragraph>

    <MarkedList>
      <li><Code>{"{null}"}</Code> - empty attribute value</li>
      <li><Code>{"{now}"}</Code> - date and time at the time of the filter request execution</li>
      <li><Code>{"{today}"}</Code> - the date at the time of the filter request execution</li>
      <li><Code>{"{this_week}"}</Code> - midnight of the current Monday (at the time of request execution)
      </li>
      <li><Code>{"{this_month}"}</Code> - midnight of the first day of the current month (at the time of request execution)
      </li>
      <li><Code>{"{this_year}"}</Code> - midnight of the first of January of the current year (at the time of request execution)
      </li>
    </MarkedList>
  </>
));
