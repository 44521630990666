/* eslint-disable @typescript-eslint/no-explicit-any, max-len, id-length */
import styled, { keyframes } from "styled-components";
import { Flex, RaisedButton } from "@evergis/ui";

import { ContainerWithPadding } from "components/styled/layout/ContainerWithPadding";
import { Footer } from "../../components/Footer/Footer";
import background from "./big-stub.png";

const dash = keyframes`
  from {
    stroke-dashoffset: 9500;
  }
  to {
    stroke-dashoffset: 5400;
  }
`;

export const Background = styled(ContainerWithPadding)`
  position: relative;
  min-height: 100vh;
  padding-top: 0;
  overflow: hidden;
    
  :after {
    content: "";
    background: url("${background}");
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const Container = styled(Flex)`
  flex-direction: column;
  min-width: 22.5rem;
  height: 20.6875rem;
  margin: auto;
  justify-content: flex-end;
  align-items: center;
`;

export const Description = styled.span`
  width: 100%;
  font-size: 2.25rem;
  font-weight: 300;
  letter-spacing: -0.2px;
  color: #000000;
  opacity: 0.87;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
`;

export const ButtonAppContainer = styled(Flex)`
  padding: 1.5rem 0;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-83%);
  height: 10.3rem;
`;

export const Svg = styled.svg.attrs<any>({
  viewBox: () => "0 0 1920 163",
  x: 0,
  y: 0,
})`
  width: 120rem;
  height: inherit;
`;

export const BlueLine = styled.path.attrs<any>({
  d:
    "M0,94h797l63-93h31v93h15c3.3,45.3,21,68,53,68s49.7-22.7,53-68l64-93l33,1v92h811",
  id: "line",
})`
  fill: transparent;
  stroke: #00aaff;
  stroke-width: 2;
  stroke-linecap: square;
  stroke-linejoin: round;
  stroke-dasharray: 1400 2600;
  stroke-dashoffset: 9500;
  animation: ${dash} 4s linear infinite;
`;

export const First4 = styled.path.attrs<any>({
  d:
    "M880.5,10.7h-12.5l-64.5,97.6v6.5H869V150h11.5v-35.2h21.7v-9.7h-21.7V10.7z M869,105.1h-51.5l46.4-70.8l5.1-8.8V105.1z",
})``;

export const Zero = styled.path.attrs<any>({
  d:
    "M959.9,8.7c-14,0-24.6,5-31.9,15.1c-7.3,10.1-10.9,24.9-10.9,44.5v25c0.2,19.1,4,33.6,11.3,43.6c7.3,10,17.9,15,31.7,15\n" +
    "\tc14,0,24.7-5.1,31.9-15.3c7.2-10.2,10.8-25.1,10.8-44.7V66.4c-0.3-18.9-4-33.3-11.3-43C984.2,13.6,973.7,8.7,959.9,8.7z M991.3,93.7\n" +
    "\tc-0.1,15.9-2.7,27.9-8,36.2s-13,12.3-23.2,12.3c-10.3,0-18.1-4.2-23.5-12.6c-5.4-8.4-8.1-20.6-8.1-36.5V65.8\n" +
    "\tc0.1-15.6,2.8-27.3,8-35.3c5.2-8,13-12,23.4-12c10.5,0,18.4,4.1,23.6,12.2c5.2,8.2,7.8,20.2,7.8,36.2V93.7z",
})``;

export const Second4 = styled.path.attrs<any>({
  d:
    "M1097.7,105.1V10.7h-12.5l-64.5,97.6v6.5h65.6V150h11.5v-35.2h21.7v-9.7H1097.7z M1086.2,105.1h-51.5l46.4-70.8l5.1-8.8\n" +
    "\tV105.1z",
})``;

export const Footer404 = styled(Footer)`
  background: transparent;
  position: absolute;
  margin-top: -9.625rem;
`;

export const ToMapButton = styled(RaisedButton)`
  margin-left: 1.25rem;
`;
