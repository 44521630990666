import * as Yup from "yup";

const email = Yup.string()
  .email("Проверьте правильность введённого email.")
  .required("Поле обязательно для заполнения.");

const username = Yup.string()
  .matches(/^[A-Za-z0-9_]+$/, {
    message: "Логин должен содержать только латинские буквы, цифры и знак подчеркивания",
    excludeEmptyString: true,
  })
  .required("Поле обязательно для заполнения.");

const password = Yup.string()
  .matches(/^.{6,}$/, {
    message: "Минимальная длина пароля 6 символов.",
    excludeEmptyString: true,
  })
  .required("Поле обязательно для заполнения.");

const passwordConfirm = Yup.string()
  .oneOf([Yup.ref("password1"), null], "Пароли не совпадают, повторите ввод.")
  .required("Поле обязательно для заполнения.");

export const signUpValidationSchema = Yup.object().shape({
  email,
  username,
  password1: password,
  password2: passwordConfirm,
});
