import React, { FC, memo } from "react";

import { CenteredContentPage } from "components/styled/layout/CenteredContentPage";
import { Form } from "./Form";

const SignUpComponent: FC = () => (
  <CenteredContentPage>
    <Form />
  </CenteredContentPage>
);

export const SignUp = memo(SignUpComponent);
