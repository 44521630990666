import React, { FC, memo, useCallback, useMemo } from "react";
import { INotificationItem, Notifications as NotificationsUI } from "@evergis/ui";
import { useTranslation } from "react-i18next";

import { NotificationContainer } from "./styled";

import { APP_CONFIG } from "../../appConfig";

import { enhance } from "./enhance";

import { MessagesTextType, NotificationsProps } from "./types";

export const NotificationsComponent: FC<NotificationsProps> = ({ messages, closeNotification }) => {
  const { t } = useTranslation();
  let containerEl: HTMLElement | null = null;

  const MessagesTextMap: Record<MessagesTextType, JSX.Element> = useMemo(() => ({
    EMAIL_SENT_DESC: (
      <span>
        {" "}
        {t("clickLinkToContinue")}<br />
        {t("notificationWarning1")} {APP_CONFIG.CONTACT.NOREPLY_EMAIL} {t("notificationWarning2")}
      </span>
    ),
  }), [t]);

  const isMapMessage = useCallback((item?: string | JSX.Element): item is MessagesTextType => {
    return typeof item === "string" && !!MessagesTextMap[item as MessagesTextType];
  }, [MessagesTextMap]);

  const getMessages = useMemo(() => {
    if (!messages) return [];

    return messages.map(({ description, ...message }) => ({
      ...message,
      onCloseRequest: () => closeNotification && closeNotification(message.id as string),
      description: isMapMessage(description) ? MessagesTextMap[description] : description,
    }));
  }, [messages, closeNotification, isMapMessage, MessagesTextMap]);

  return (
    <NotificationContainer ref={container => (containerEl = container)}>
      <NotificationsUI
        items={getMessages as INotificationItem[]}
        container={containerEl}
        onCloseRequest={(id: string) => closeNotification && closeNotification(id)}
        closeExtraItems
      />
    </NotificationContainer>
  );
};

export const Notifications = enhance(memo(NotificationsComponent));
