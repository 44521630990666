import styled from "styled-components";

export const PageProgressContainer = styled.div`
  width: 18.75rem;
`;

export const PageProgressTitle = styled.div`
  margin-bottom: 1.25rem;
  text-align: center;
`;
