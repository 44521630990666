/* eslint-disable max-len, id-length */
import styled from "styled-components";
import { H2, transition } from "@evergis/ui";

import { HeaderLogoProps, LogoProps } from "./types";

export const HeaderLogoSvg = styled.svg.attrs({
  x: 0,
  y: 0,
  viewBox: "0 0 102 24",
})<Pick<HeaderLogoProps, "offsetLeft">>`
  position: relative;
  left: ${({ offsetLeft }) => offsetLeft ?? 0};
  width: calc(8rem + ${({ offsetLeft }) => offsetLeft ?? 0});
`;

export const Logo = styled.path.attrs({
  d:
    "M21.7,7.5v8.9c0,1.1-0.6,2.1-1.5,2.7l-7.7,4.5c-1,0.6-2.1,0.6-3.1,0l-7.7-4.5c-1-0.6-1.5-1.6-1.5-2.7V7.5\n" +
    "\tc0-1.1,0.6-2.1,1.5-2.7l7.7-4.5c1-0.6,2.1-0.6,3.1,0l7.7,4.5C21.1,5.4,21.7,6.4,21.7,7.5z M16.4,9.7c0.4-0.2,0.9-0.1,1.1,0.3\n" +
    "\tc0.2,0.4,0.1,0.9-0.3,1.1l-5.9,3.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1l-5.9-3.2c-0.3-0.1-0.4-0.4-0.4-0.7\n" +
    "\ts0.2-0.6,0.4-0.7l5.9-3.2c0.2-0.1,0.5-0.1,0.8,0l3,1.6c0.4,0.2,0.5,0.7,0.3,1.1c-0.1,0.1-0.2,0.2-0.3,0.3l0,0l-4.3,2.3\n" +
    "\tc-0.1,0.1-0.1,0.3,0,0.4l0.7,0.4c0.2,0.1,0.3,0.1,0.5,0L16.4,9.7z M11.8,8.6l-0.7-0.4c-0.2-0.1-0.3-0.1-0.5,0l-3.6,2\n" +
    "\tc-0.1,0.1-0.1,0.3,0,0.4l0.7,0.4C7.8,11,8,11,8.1,10.9l3.6-2C11.9,8.9,11.9,8.7,11.8,8.6z M10.5,17.5c0.1,0.1,0.2,0.1,0.4,0.1\n" +
    "\tc0.1,0,0.3,0,0.4-0.1l5.9-3.2c0.4-0.2,0.5-0.7,0.3-1.1s-0.7-0.5-1.1-0.3l-5.3,2.8c-0.2,0.1-0.3,0.1-0.5,0l-5.3-2.8\n" +
    "\tc-0.4-0.2-1,0-1.1,0.4c-0.1,0.4,0.1,0.8,0.4,1L10.5,17.5z",
  fillRule: "evenodd",
  clipRule: "evenodd",
})<HeaderLogoProps>`
  fill: ${({ theme: { palette }, light, hideLogo }) =>
    hideLogo ? "transparent" : light ? palette.iconContrast : palette.primary};
  transition: ${({ hideLogo }) => (hideLogo ? "none" : `fill ${transition.show}`)};
`;

export const Evergis = styled.path.attrs({
  d:
    "M41.8,14.4c-0.9,2.4-3.3,4-6.1,4c-3.5,0-6.5-2.7-6.5-6.2c0.1-3.5,3-6.3,6.6-6.3c3.5,0,6.4,2.8,6.4,6.2c0,0.3,0,0.7-0.1,0.9\n" +
    "\th-10c0.4,1.6,1.9,2.8,3.6,2.8c1.2,0,2.4-0.6,3-1.5L41.8,14.4L41.8,14.4z M39.4,11.1c-0.4-1.5-1.9-2.8-3.5-2.8\n" +
    "\tc-1.7,0-3.2,1.2-3.6,2.8H39.4z M48.3,13.8l3.3-7.5h2.8l-4.9,11.1c-0.5,1.1-1.7,1.1-2.2,0L42.3,6.3h2.8L48.3,13.8z M66.9,14.4c-0.9,2.4-3.3,4-6.1,4c-3.5,0-6.5-2.7-6.5-6.2c0.1-3.5,3-6.3,6.6-6.3c3.5,0,6.4,2.8,6.4,6.2c0,0.3,0,0.7-0.1,0.9\n" +
    "\th-10c0.4,1.6,1.9,2.8,3.6,2.8c1.2,0,2.4-0.6,3-1.5L66.9,14.4L66.9,14.4z M64.4,11.1c-0.4-1.5-1.9-2.8-3.5-2.8\n" +
    "\tc-1.7,0-3.1,1.2-3.6,2.8H64.4z M74.3,5.9v2.4c-2.1,0-3.4,1.2-3.4,3.4V18h-2.7v-6.7c0-3.2,2.1-5.3,5.7-5.3L74.3,5.9L74.3,5.9z M90.1,1.9c0.9,0,1.6,0.7,1.6,1.5C91.7,4.3,91,5,90.1,5c-0.9,0-1.6-0.7-1.6-1.5C88.6,2.6,89.3,1.9,90.1,1.9z M91.5,6.3V18\n" +
    "\th-2.7V6.3H91.5z M97.5,15.9c1.1,0,1.8-0.7,1.8-1.5c0-0.7-0.7-1.2-1.6-1.4c-2.6-0.5-4.5-1.5-4.5-3.6c0-2,1.8-3.6,4.3-3.6\n" +
    "\tc2.4,0,4.1,1.5,4.3,3.7h-2.6c-0.1-0.8-0.7-1.4-1.6-1.4c-0.8,0-1.6,0.4-1.6,1.1c0,1,1.6,1.3,2.9,1.6c1.8,0.4,3.2,1.5,3.2,3.3\n" +
    "\tc0,2.2-1.8,3.9-4.5,3.9c-2.6,0-4.4-1.5-4.5-3.9h2.7C95.8,15.3,96.6,15.9,97.5,15.9z M87.2,11.4h-0.9h-1.8h-3.7v2h3.5c-0.5,1.5-1.9,2.5-3.5,2.5c-2.1,0-3.8-1.7-3.8-3.7s1.7-3.7,3.8-3.7c1.3,0,2.5,0.7,3.2,1.8h3\n" +
    "\tc-0.9-2.4-3.3-4.2-6.1-4.2c-3.5,0-6.5,2.8-6.5,6.2c0,3.4,3,6.2,6.5,6.2s6.5-2.8,6.5-6.2C87.2,11.8,87.2,11.6,87.2,11.4z",
})<HeaderLogoProps>`
  fill: ${({ theme: { palette }, light }) => (light ? "rgba(255, 255, 255, 0.87)" : palette.textPrimary)};
  transition: fill ${transition.show};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled(H2)`
  margin-left: 1.0625rem;
  margin-right: auto;
`;

export const LogoSvg = styled.svg.attrs({
  x: 0,
  y: 0,
  viewBox: "0 0 184.2 210.2",
})<LogoProps>`
  fill: ${({ theme: { palette }, mono }) => (mono ? "#fff" : palette.primary)};
  width: ${({ width }) => width || "2.625rem"};
`;

export const Polygon = styled.polygon.attrs({
  points: "92.1,73.6 56.9,93.7 69,100.6 104.2,80.6",
})``;

export const Path = styled.path.attrs({
  d: "M180.2,49.6L96.1,1.1c-2.5-1.4-5.5-1.4-8,0L4,49.6c-2.5,1.4-4,4.1-4,6.9v97.1c0,2.9,1.5,5.5,4,6.9l84.1,48.6c2.5,1.4,5.5,1.4,8,0l84.1-48.6c2.5-1.4,4-4.1,4-6.9V56.6C184.2,53.7,182.7,51.1,180.2,49.6z M147.2,121.5l-53.1,30.3c-0.6,0.3-1.3,0.5-2.1,0.5c-0.7,0-1.5-0.2-2.1-0.5l-53.1-30.3c-1.1-0.6-1.1-1.7,0-2.4l6.9-3.9c1.1-0.6,3-0.6,4.1,0l44.2,25.2l44.2-25.2c1.1-0.6,3-0.6,4.1,0l6.9,3.9C148.4,119.8,148.4,120.9,147.2,121.5z M147.2,94.9l-53.1,30.3c-0.6,0.3-1.3,0.5-2.1,0.5c-0.7,0-1.5-0.2-2.1-0.5L36.9,94.9c-1.1-0.6-1.1-1.7,0-2.4L90,62.3c1.1-0.6,3-0.6,4.1,0l30,17.1c1.1,0.6,1.1,1.7,0,2.4L80,106.9l12.1,6.9l44.2-25.2c1.1-0.6,3-0.6,4.1,0l6.9,3.9C148.4,93.2,148.4,94.3,147.2,94.9z",
})``;
