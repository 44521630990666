import React, { FC, memo, useCallback, useEffect, useState } from "react";

export type ScrollObserver = (scrollHeight: number) => void;

export type WithScrollProps = {
  scrollHeight: number;
  observerScroll: (observer: ScrollObserver) => void;
}

export const withScroll = <P,>(EnhancementComponent: FC): FC<P> =>
  memo((props) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    let scrollObserver: ScrollObserver | null = null;

    const observerScroll = useCallback((observer: ScrollObserver) => {
      scrollObserver = observer;
    }, [scrollObserver]);

    const handleScroll = useCallback(() => {
      setScrollHeight(window.scrollY);
      typeof scrollObserver === "function" && scrollObserver(window.scrollY);
    }, []);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        scrollObserver = null;
      };
    }, []);

    return (
      <EnhancementComponent
        {...props}
        scrollHeight={scrollHeight}
        observerScroll={observerScroll}
      />
    );
  });
