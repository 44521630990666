import { combineReducers, ReducersMapObject } from "redux";

import { reducer as user, UserState } from "./user";
import { reducer as notifications, NotificationsState } from "./notifications";
import { reducer as theme, ThemeState } from "./theme";
import { reducer as portalConfig, PortalConfigState } from "./portalConfig";

export type RootState = {
  user: UserState;
  notifications: NotificationsState;
  theme: ThemeState;
  portalConfig: PortalConfigState;
};

export function createReducer(injectedReducers?: ReducersMapObject) {
  return combineReducers({
    user,
    notifications,
    theme,
    portalConfig,
    ...injectedReducers,
  });
}
