/* eslint-disable @typescript-eslint/no-explicit-any, max-len */
import React, { FC, memo, useEffect, useState } from "react";
import throttle from "lodash/throttle";

import { screenConditions, ScreenSizeType } from "../styles/queries";

export type WithScreenSizeProps = {
  isMobile: boolean;
  isLaptop: boolean;
}

const getCurrentScreenSizeType = (): Nullable<ScreenSizeType> => {
  const { outerWidth: width, outerHeight: height } = window;

  return Object.entries(screenConditions)
    .reduce<Nullable<ScreenSizeType>>(
      (screenSize, [type, condition]) =>
        screenSize || (condition({ width, height }) && (type as ScreenSizeType) || null)
    , null);
};

export const withScreenSize = <P,>(EnhancementComponent: FC): FC<P> => memo((props) => {
    const [screenSizeType, setScreenSizeType] = useState<Nullable<ScreenSizeType>>(null);

    const handleResize = throttle(() => {
      setScreenSizeType(getCurrentScreenSizeType());
    }, 149);

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <EnhancementComponent
        {...props}
        isMobile={screenSizeType === "mobile"}
        isLaptop={screenSizeType === "laptop"}
      />
    );
  });
