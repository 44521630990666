import { createAction } from "redux-act";
import { UserInfoWithPhoto } from "@evergis/api/dist/services/Account";

import { ILimits } from "api/types";

export const emailNotConfirmedSet = createAction<boolean>("user/emailNotConfirmedSet");
export const emailSet = createAction<string>("user/setEmail");
export const userReset = createAction("user/userReset");
export const userSet = createAction<UserInfoWithPhoto>("user/userSet");
export const loadingSet = createAction<boolean>("user/loadingSet");
export const usedProjectsSet = createAction<any>("user/usedProjectsSet");
export const lastProjectsSet = createAction<any>("user/lastProjectsSet");
export const sessionIdSet = createAction<string>("user/sessionIdSet");
export const checkLimitsReset = createAction("user/checkLimitsReset");
export const checkLimitsSet = createAction<ILimits>("user/checkLimitsSet");
export const userErrorSet = createAction<string>("user/userErrorSet");
export const userSocialErrorSet = createAction<string>("user/userSocialErrorSet");
export const userSuccessSet = createAction<string | void>("user/userSuccessSet");
