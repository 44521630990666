import { ROUTES } from "appConfig";

import { api } from "api/api";
import { i18n } from "../i18n";

import { ServerErrorType } from "api/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getResponseError = async (error: any, json?: any): Promise<string> => {
  const { t } = i18n;
  const serverMessage = json || (await (error.origin || error).response.json());

  switch (serverMessage.ErrorType) {
    case ServerErrorType.ValidationError:
      return t("validationError");
    case ServerErrorType.RefreshTokenExpired:
      return t("tokenExpired");
    case ServerErrorType.RefreshTokenInvalid:
      return t("invalidToken");
    case ServerErrorType.TokenInvalid:
      return t("invalidCode");
    case ServerErrorType.SetUserPasswordFailed:
      return t("setPasswordError");
    case ServerErrorType.SetUserRoleFailed:
      return t("setRoleError");
    case ServerErrorType.UpdateUserFailed:
      return `${t("updateUserError")}: ${serverMessage.ExceptionMessage}`;
    case ServerErrorType.RemoveUserFailed:
      return t("userDeleteError");
    case ServerErrorType.CreateRoleFailed:
      return t("roleCreateError");
    case ServerErrorType.UpdateRoleFailed:
      return t("updateRoleError");
    case ServerErrorType.RemoveUserRoleFailed:
      return t("roleDeleteError");
    case ServerErrorType.ChangeActiveStateFailed:
      return t("changeActiveStateError");
    case ServerErrorType.RemoveRoleFailed:
      return t("roleDeleteError");
    case ServerErrorType.UserNotFound:
      return t("userNotFound");
    case ServerErrorType.RoleNotFound:
      return t("roleNotFound");
    case ServerErrorType.EmailNotConfirmed:
      return t("emailNotConfirmed");
    case ServerErrorType.DuplicateEmailError:
      return t("duplicateEmailError");
    case ServerErrorType.InvalidPassword:
      return t("invalidPassword");
    case ServerErrorType.RoleExists:
      return t("roleExists");
    case ServerErrorType.UserExists:
      return t("userExists");
    case ServerErrorType.UserLockedError:
      return t("userBlocked");
    case ServerErrorType.InvalidEmail:
      return t("enterValidEmail");
    case ServerErrorType.InvalidUsername:
      return t("invalidUsername");
    case ServerErrorType.EmailNotChanged:
      return t("invalidCodeOrEmail");
    case ServerErrorType.NotSpecified:
    default:
      return serverMessage.ExceptionMessage || t("unkownError");
  }
};

export const openAppLink = (): string => (api.account.isAuth ? ROUTES.MAP : ROUTES.SIGN_IN);
