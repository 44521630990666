import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Image } from "@evergis/ui";

import { HeaderLogo as Logo } from "../../../components/Logo/HeaderLogo";

import { getPortalConfigSelector } from "../../../ducks/portalConfig";

const PageContainer = styled.div`
  padding: 2rem;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;

  #custom-logo {
    position: absolute;
  }
`;

export const PageLayout: FC = ({ children }) => {
  const config = useSelector(getPortalConfigSelector);
  const { customLogo } = config?.settings || {};

  return (
    <PageContainer>
      {config !== undefined && (
        <LogoContainer>
          {customLogo ? (
            <>
              <Image id="custom-logo" src={customLogo.src} width={customLogo.width} height={customLogo.height} />
              {customLogo.preserveText && <Logo hideLogo offsetLeft={`${(customLogo.width ?? 0) - 35}px`} />}
            </>
          ) : (
            <Logo />
          )}
        </LogoContainer>
      )}
      {children}
    </PageContainer>
  );
};
