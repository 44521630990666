import React, { FC, memo } from "react";

import {
  Paragraph,
  MarkedList,
  Code,
  Title,
  SectionTitle,
} from "../../styled";

export const DataTypes: FC = memo(() => (
  <>
    <Title>Типы значений</Title>
    <SectionTitle>Целые числа</SectionTitle>

    <Paragraph>
      Положительные или отрицательные целочисленные значения, умещающиеся в 64 бит:
    </Paragraph>

    <Code block>
      0<br />
      5<br />
      -100<br />
      123456789
    </Code>

    <SectionTitle>Дробные значения</SectionTitle>

    <Paragraph>
      Положительные и отрицательные дробные значения с разделителем &quot;.&quot;.
      Разделение разрядов запятой не поддерживается.
    </Paragraph>

    <Code block>
      0.0<br />
      3.1415<br />
      -20.9
    </Code>

    <SectionTitle>Текстовые значения</SectionTitle>

    <Paragraph>
      Текстовые значения обрамляются одинарыми или двойными кавычками. При
      необходимости ввести значение с кавычками, перед кавычкой нужно поставить знак &quot;&quot;.
    </Paragraph>

    <Code block>
      &quot;&quot;<br />
      &quot;Строковое значение&quot;<br />
      &apos;Также строковое значение с двойными &quot; кавычками&apos;<br />
      &quot;Двойные кавычки \&quot; обрамленные двойными кавычками&quot;<br />
    </Code>

    <SectionTitle>Дата и время</SectionTitle>

    <Paragraph>
      Значения типа дата и время представляются текстовым значением, перед которым
      стоит знак &quot;#&quot;. Значения записываются в формате YYYY-mm-DD HH:MM:SS.
    </Paragraph>

    <Code block>
      #&quot;2019-10-08&quot;<br />
      #&apos;2000-01-01 00:00:01&apos;
    </Code>

    <SectionTitle>Период времени</SectionTitle>

    <Paragraph>
      Период времени используется в комбинации с датами и временем, и позволяет
      выразить выражения типа &quot;ДАТА +/- ПЕРИОД&quot;. Значение периода задается символом
      &quot;#&quot;, за которым идет целое число и указатель размерности.
    </Paragraph>

    <Code block>
      #1s (одна секунда)<br />
      #5h (пять часов)<br />
      #365d (365 дней)
    </Code>

    <SectionTitle>Поддерживаемые размерности:</SectionTitle>

    <MarkedList>
      <li><Code>ms</Code> - миллисекунды</li>
      <li><Code>s</Code> - секунды</li>
      <li><Code>m</Code> - минуты</li>
      <li><Code>h</Code> - часы</li>
      <li><Code>d</Code> - дни</li>
      <li><Code>mth</Code> - месяцы</li>
      <li><Code>y</Code> - годы</li>
    </MarkedList>

    <SectionTitle>Специальные значения</SectionTitle>

    <Paragraph>
      Специальные значения обрамляются фигурными скобками: {"{ }"}.
    </Paragraph>

    <MarkedList>
      <li><Code>{"{null}"}</Code> - пустое значение атрибута</li>
      <li><Code>{"{now}"}</Code> - дата и время в момент выполнения фильтрующего запроса</li>
      <li><Code>{"{today}"}</Code> - дата в момент выполнения фильтрующего запроса</li>
      <li><Code>{"{this_week}"}</Code> - полночь текущего понедельника (на момент выполнения запроса)
      </li>
      <li><Code>{"{this_month}"}</Code> - полночь первого числа текущего месяца (на момент выполнения запроса)
      </li>
      <li><Code>{"{this_year}"}</Code> - полночь первого января текущего года (на момент выполнения запроса)
      </li>
    </MarkedList>
  </>
));
