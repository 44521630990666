import { ComponentType } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "@reach/router";
import { Link as UILink, getLinkColors } from "@evergis/ui";
import { ILinkProps } from "@evergis/ui/dist/atoms/Link/types";

const RLink = UILink.withComponent(RouterLink);

type LinkProps = ILinkProps & { to: string; $description?: boolean };

export const Link: ComponentType<LinkProps> = styled(RLink).attrs<LinkProps>(({ $description }) => ({
  description: $description ? 1 : 0,
}))`
  &:visited {
    color: ${({ theme: { link }, ...props }) => link.color[getLinkColors(props)]};
  }
`;

export const ExternalLink = styled(UILink)`
  &:visited {
    color: ${({ theme: { link }, ...props }) => link.color[getLinkColors(props)]};
  }
`;
