import { compose } from "redux";
import { connect } from "react-redux";

import mapStateToProps from "./structured-selectors";
import { closeNotification } from "../../ducks/notifications";

const mapDispatchToProps = {
  closeNotification,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose(withConnect);
