import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeName } from "@evergis/ui";

import { Link, ExternalLink } from "components/styled/links/Link";
import { Text, Background, Contacts, FooterContainer, InfoContainer, LinkButtons, IconsContainer } from "./styled";
import { ThemeProvider } from "../ThemeProvider";

import { APP_CONFIG } from "appConfig";

import { getPortalConfigSelector } from "../../ducks/portalConfig";
import { FooterProps } from "./types";

const FooterComponent: FC<FooterProps> = ({ darkText, withLink, className }) => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const { disabledComponents } = config?.settings || {};

  if (config === undefined || disabledComponents?.footer === true) {
    return null;
  }

  return (
    <Background className={className}>
      <FooterContainer>
        {withLink && (
          <Link to={"/agreements/user-agreement"}>
            <Text dark={darkText}>{t("termsOfService")}</Text>
          </Link>
        )}
        <InfoContainer column>
          <Text dark={darkText}>{t("everpointCompany")}</Text>
          <Text dark={darkText}>{APP_CONFIG.CONTACT.ADDRESS}</Text>
          <Contacts dark={darkText}>
            {APP_CONFIG.CONTACT.PHONE}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{APP_CONFIG.CONTACT.INFO_EMAIL}
          </Contacts>
          <IconsContainer>
            <ThemeProvider themeName={darkText ? ThemeName.Light : ThemeName.Dark}>
              <>
                {/* <ExternalLink
                  href={APP_CONFIG.MEDIA.FACEBOOK}
                  target={"_blank"}
                >
                  <LinkButtons kind={"social_fb"} />
                </ExternalLink>*/}
                <ExternalLink href={APP_CONFIG.MEDIA.MEDIUM} target={"_blank"}>
                  <LinkButtons kind={"social_medium"} />
                </ExternalLink>
              </>
            </ThemeProvider>
          </IconsContainer>
        </InfoContainer>
      </FooterContainer>
    </Background>
  );
};

export const Footer = memo(FooterComponent);
