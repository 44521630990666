import { useSelector } from "react-redux";
import { randomInteger } from "@evergis/ui";

import { getPortalConfigSelector, PortalConfiguration } from "../../ducks/portalConfig";

export const useLoginScreenSettings = (): PortalConfiguration["settings"]["loginScreen"] => {
  const config = useSelector(getPortalConfigSelector);
  const { loginScreen } = (config?.settings as PortalConfiguration["settings"]) || {};
  const currentScreen = loginScreen?.[randomInteger(0, loginScreen.length - 1)];

  return {
    ...currentScreen,
    hasContent:
      !!currentScreen?.logo ||
      !!currentScreen?.title?.text ||
      !!currentScreen?.description?.text ||
      !!currentScreen?.footer?.logo ||
      !!currentScreen?.footer?.networks?.length ||
      !!currentScreen?.footer?.links?.length,
    backgroundImage: currentScreen?.backgroundImage,
    backgroundVideo: currentScreen?.backgroundVideo,
    title: {
      ...currentScreen?.title,
      text: currentScreen?.title?.text,
    },
    description: {
      ...currentScreen?.description,
      text: currentScreen?.description?.text,
    },
  };
};
