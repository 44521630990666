import { createAction } from "redux-act";

import { MessageType } from "./types";

export const addNotification = createAction<MessageType>(
  "notification/add-notification"
);

export const closeNotification = createAction<string>(
  "notification/close-notification"
);
