import React, { FC, memo } from "react";

import {
  HelpTitle,
  Paragraph,
  MarkedList,
  Code,
} from "../../styled";

export const ForWhat: FC = memo(() => (
  <>
    <HelpTitle>Attribute query language</HelpTitle>

    <Paragraph>Attribute queries in EverGIS are used for:</Paragraph>

    <MarkedList>
      <li>
        writing conditions for filtering objects in layers, when requesting data, when choosing the applied style
      </li>
      <li>
        for dynamic calculation of values in classifiers
      </li>
      <li>
        for calculation of attribute values based on other attributes in bulk editing operations
      </li>
    </MarkedList>

    <Paragraph>
      Queries are applied to layers with geo data and use the system attribute names when executed.
    </Paragraph>

    <Paragraph>
      For example, if there is a layer with the attributes <Code>str_attr</Code> (text), <Code>int_attr</Code> (integer), queries can be made for such a layer:
    </Paragraph>

    <MarkedList>
      <li><Code>str_attr is {"{null}"}</Code> - returns <Code>true</Code> if the attribute value of the object is not set
      </li>
      <li><Code>str_attr + str_attr</Code> - returns a text value in which the attribute value is repeated twice
      </li>
      <li><Code>int_attr &gt; 10 &amp;&amp; int_attr &lt; 20</Code> - returns <Code>true</Code> if the attribute value is between 10 and 20
      </li>
      <li><Code>int_attr * 2 + 5</Code> - returns an integer-the result of executing the expression</li>
    </MarkedList>
  </>
));
