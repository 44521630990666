import { compose } from "redux";
import { connect } from "react-redux";

import mapStateToProps from "./structured-selectors";
import { resetSuccessError, signIn } from "../../ducks/user";

const mapDispatchToProps = {
  onSignIn: signIn,
  resetSuccessError,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const enhance = compose(withConnect);
