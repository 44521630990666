/* eslint-disable max-len */
import React from "react";
import loadable, { Loadable } from "loadable-components";
import { withAuth } from "../hoc/Redirect";
import { ROUTES } from "../appConfig";

export { Main } from "./main";
export const Profile = loadable(() =>
  import("./profile").then(({ Profile: ProfileResponse }) => ProfileResponse) as never
);
export { SignIn } from "./sign-in";
export { SignUp } from "./sign-up";
export { NotFound } from "./404";
export { Forbidden } from "./403";

export { QueryLanguagePage } from "./external/query-language";
export { WmsQgisPage } from "./external/wms-qgis";

import "../i18n/config";

export const AccessRecoverySent = loadable(() =>
  import("./access-recovery-sent").then(
    ({ AccessRecoverySent: AccessRecoverySentResponse }) => AccessRecoverySentResponse
  ) as never
);
export const AccessRecovery = loadable(() =>
  import("./access-recovery").then(({ AccessRecovery: AccessRecoveryResponse }) => AccessRecoveryResponse) as never
);
export const RestorePassword = loadable(() =>
  import("./restore-password").then(({ RestorePassword: RestorePasswordResponse }) => RestorePasswordResponse) as never
);
export const RestoreOutdated = loadable(() =>
  import("./restore-outdated").then(({ RestoreOutdated: RestoreOutdatedResponse }) => RestoreOutdatedResponse) as never
);
export const RestoreSuccess = loadable(() =>
  import("./restore-success").then(({ RestoreSuccess: RestoreSuccessResponse }) => RestoreSuccessResponse) as never
);
export const SignUpSuccess = loadable(() =>
  import("./sign-up-success").then(({ SignUpSuccess: SignUpSuccessResponse }) => SignUpSuccessResponse) as never
);
export const SignUpConfirm = loadable(() =>
  import("./sign-up-confirm").then(({ SignUpConfirm: SignUpConfirmResponse }) => SignUpConfirmResponse) as never
);
export const EmailConfirm = loadable(() =>
  import("./email-confirm").then(({ EmailConfirm: EmailConfirmResponse }) => EmailConfirmResponse) as never
);

export const About = loadable(() =>
  import("./about").then(({ About: AboutResponse }) => AboutResponse) as never
);

export const Agreements = loadable(() =>
  import("./agreements").then(({ Agreements: AgreementsResponse }) => AgreementsResponse) as never
);

export const Help = loadable(() => import("./help").then(({ Help: HelpResponse }) => HelpResponse) as never);

type AgreementSubPathKeys = keyof typeof ROUTES["AGREEMENTS"]["subPaths"]
type HelpSubPathKeys = keyof typeof ROUTES["HELP"]["subPaths"]
type SocSubPathKeys = keyof typeof ROUTES["SOC_AUTH"]["subPaths"]

const AGREEMENT_SECTION_MODULES: Record<AgreementSubPathKeys, Loadable<{ path?: string }>> = {
  userAgreement: loadable(() => import("./user-agreement").then(({ UserAgreement }) => UserAgreement)),
  privacyPolicy: loadable(() => import("./privacy-policy").then(({ PrivacyPolicy }) => PrivacyPolicy)),
  accessibleResources: loadable(() => import("./accessible-resources").then(({ AccessibleResources }) => AccessibleResources)),
};
const HELP_SECTION_MODULES: Record<HelpSubPathKeys, Loadable<{ path?: string }>> = {
  getStarted: loadable(() => import("./help/get-started").then(({ GetStarted }) => GetStarted)),
  createMap: loadable(() => import("./help/create-map").then(({ CreateMap }) => CreateMap)),
  addLayer: loadable(() => import("./help/add-layer").then(({ AddLayer }) => AddLayer)),
  importData: loadable(() => import("./help/import-data").then(({ ImportData }) => ImportData)),
  updateData: loadable(() => import("./help/update-data").then(({ UpdateData }) => UpdateData)),
  buffers: loadable(() => import("./help/buffers").then(({ Buffers }) => Buffers)),
  availableArea: loadable(() => import("./help/available-area").then(({ AvailableArea }) => AvailableArea)),
  geometrySelection: loadable(() => import("./help/geometry-selection").then(({ GeometrySelection }) => GeometrySelection)),
  layerStyle: loadable(() => import("./help/layer-style").then(({ LayerStyle }) => LayerStyle)),
  sharing: loadable(() => import("./help/sharing").then(({ Sharing }) => Sharing)),
  queryLanguage: loadable(() => import("./help/query-language").then(({ QueryLanguage }) => QueryLanguage)),
  wmsQgis: loadable(() => import("./help/wms-qgis").then(({ WmsQgis }) => WmsQgis)),
};
const SOC_AUTH_SECTION_MODULES: Record<SocSubPathKeys, Loadable<{ path?: string }>> = {
  vk: loadable(() => import("./account/networks/AccountVkRedirect").then(({ AccountVkRedirect }) => AccountVkRedirect)),
  google: loadable(() => import("./account/networks/AccountGoogleRedirect").then(({ AccountGoogleRedirect }) => AccountGoogleRedirect)),
  facebook: loadable(() => import("./account/networks/AccountFacebookRedirect").then(({ AccountFacebookRedirect }) => AccountFacebookRedirect)),
};
const SOC_BIND_SECTION_MODULES: Record<SocSubPathKeys, Loadable<{ path?: string }>> = {
  vk: loadable(() => import("./bind/networks/BindVkRedirect").then(({ BindVkRedirect }) => BindVkRedirect)),
  google: loadable(() => import("./bind/networks/BindGoogleRedirect").then(({ BindGoogleRedirect }) => BindGoogleRedirect)),
  facebook: loadable(() => import("./bind/networks/BindFacebookRedirect").then(({ BindFacebookRedirect }) => BindFacebookRedirect)),
};

const createSections = <T extends string>(modules: Record<T, Loadable<{ path?: string }>>, rootPath: string, subPaths: Record<T, string>): JSX.Element[] => {
  return (Object.keys(modules) as T[]).reduce<JSX.Element[]>((acc, key) => {
    const Component = modules[key] as Loadable<{ path?: string }>;

    return [...acc, <Component key={key} path={subPaths[key].replace(rootPath, "")}/>];
  }, []);
};

export const AgreementSections = createSections(AGREEMENT_SECTION_MODULES, ROUTES.AGREEMENTS.path, ROUTES.AGREEMENTS.subPaths);

export const HelpSections = createSections(HELP_SECTION_MODULES, ROUTES.HELP.path, ROUTES.HELP.subPaths);

export const prefetchAll = () =>
  Promise.all([
    Profile.load(),
    About.load(),
    Agreements.load(),
    Help.load(),
    ...(Object.keys(AGREEMENT_SECTION_MODULES) as AgreementSubPathKeys[]).map(key => AGREEMENT_SECTION_MODULES[key].load()),
    ...(Object.keys(HELP_SECTION_MODULES) as HelpSubPathKeys[]).map(key => HELP_SECTION_MODULES[key].load()),
    ...(Object.keys(SOC_AUTH_SECTION_MODULES) as SocSubPathKeys[]).map(key => SOC_AUTH_SECTION_MODULES[key].load()),
    ...(Object.keys(SOC_BIND_SECTION_MODULES) as SocSubPathKeys[]).map(key => SOC_BIND_SECTION_MODULES[key].load()),
  ]);

export const PrivateProfile = withAuth(Profile);
