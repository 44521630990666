import { resources as commonTranslations } from "@evergis/common";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import ru from "./ru.json";

export const DEFAULT_LANGUAGE = "ru";

const resources = {
  en: {
    translations: en,
    ...commonTranslations.en,
  },
  ru: {
    translations: ru,
    ...commonTranslations.ru,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    ns: Object.keys(resources[DEFAULT_LANGUAGE]),
    interpolation: {
      escapeValue: false,
    },
    resources,
    supportedLngs: Object.keys(resources),
  });

export { i18n };
