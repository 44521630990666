import { createStore, applyMiddleware, compose, ReducersMapObject, AnyAction, Store } from "redux";
import thunk from "redux-thunk";

import { INITIAL_STATE as userInitialState } from "./user";
import { INITIAL_STATE as notificationsInitialState } from "./notifications";
import { INITIAL_STATE as portalConfigInitialState } from "./portalConfig";
import { INITIAL_STATE as themeInitialState } from "./theme";
import { createReducer, RootState } from "./reducers";
import { ExtendedWindow } from "../../types/global";

const devtoolsCompose =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  (window as ExtendedWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const composeEnhancers = devtoolsCompose
  ? devtoolsCompose
  : <ExtendedWindow["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]>compose;

const middlewares = [thunk];

const enhancers = [applyMiddleware(...middlewares)];

type AsyncStore = {
  asyncReducers: ReducersMapObject;
  injectReducers: (reducers: ReducersMapObject) => Store;
};

export function configureStore(initialState: Partial<RootState> = {}) {
  const store = createStore<RootState, AnyAction, AsyncStore, {}>(
    createReducer(),
    {
      user: userInitialState,
      notifications: notificationsInitialState,
      portalConfig: portalConfigInitialState,
      theme: themeInitialState,
      ...initialState,
    },
    composeEnhancers && composeEnhancers(...enhancers),
  );

  store.asyncReducers = {};

  store.injectReducers = (reducers: ReducersMapObject) => {
    Object.entries(reducers).forEach(([key, reducer]) => {
      if (key in store.asyncReducers) return;
      store.asyncReducers[key] = reducer;
    });

    store.replaceReducer(createReducer(store.asyncReducers));

    return store;
  };

  return store;
}
