import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  createTheme,
  getThemeByName,
  IThemeConstants,
  mergeObjects,
  ThemeName,
  ThemeProvider as UiThemeProvider,
} from "@evergis/ui";

import { getThemeName } from "../../ducks/theme";
import { getPortalConfigSelector } from "../../ducks/portalConfig";

export const ThemeProvider: FC<{ themeName?: ThemeName }> = ({ children, themeName: themeNameProp }) => {
  const config = useSelector(getPortalConfigSelector);
  const { settings } = config || {};
  const themeName = useSelector(getThemeName);

  const themeWithConfig = useMemo(() => {
    const currentThemeName = themeNameProp || themeName || settings?.theme?.name || ThemeName.Light;
    const theme = getThemeByName(currentThemeName);
    let themeConstants = settings?.theme
      ? (mergeObjects(theme as unknown as Record<string, unknown>, settings.theme) as unknown as IThemeConstants)
      : theme;

    if (currentThemeName === ThemeName.Dark && settings?.theme?.paletteDark) {
      themeConstants = { ...themeConstants, palette: { ...themeConstants.palette, ...settings.theme.paletteDark } };
    }

    return createTheme(themeConstants);
  }, [settings?.theme, themeName, themeNameProp]);

  return <UiThemeProvider theme={themeWithConfig}>{children}</UiThemeProvider>;
};
