import React, { FC, memo } from "react";
import { ROUTES } from "../../appConfig";

import { Link } from "./styled";
import { LinkState, NavLinkProps } from "./types";

export const NavLinkComponent: FC<NavLinkProps> = props => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent, href }: LinkState) => ({
      "data-current": href === ROUTES.MAIN ? isCurrent : isPartiallyCurrent,
    })}
  />
);

export const NavLink = memo(NavLinkComponent);
