import React, { FC, memo } from "react";

import {
  Paragraph,
  MarkedList,
  Code,
  Title,
  SectionTitle,
} from "../../styled";

export const Operators: FC = memo(() => (
  <>
    <Title>Операторы</Title>

    <SectionTitle>Операторы сравнения</SectionTitle>

    <MarkedList>
      <li><Code>==</Code></li>
      <li><Code>!=</Code></li>
      <li><Code>&gt;</Code></li>
      <li><Code>&gt;=</Code></li>
      <li><Code>&lt;</Code></li>
      <li><Code>&lt;=</Code></li>
    </MarkedList>

    <Paragraph>
      Операторы сравнения возвращаются булевое значение сравнения левого и правого значения.
    </Paragraph>

    <Code block>
      int_attr == 2<br />
      str_attr != &quot;Строковое значение&quot;<br />
      date_attr == #&quot;2020-01-01&quot;<br />
      double_attr &gt;= 1.5 &amp;&amp; double_attr &lt; 5<br />
    </Code>

    <SectionTitle>Начинается с, заканчивается на, содержит</SectionTitle>

    <Paragraph>
      Для текстовых значений операторы <Code>==</Code> и <Code>!=</Code> поддерживают специальный синтаксис:
    </Paragraph>

    <MarkedList>
      <li><Code>attr == &quot;value%&quot;</Code>, <Code>attr != &quot;value%&quot;</Code> - значение
        начинается (не начинается) с текста &quot;value&quot;
      </li>
      <li><Code>attr == &quot;%value&quot;</Code>, <Code>attr != &quot;%value&quot;</Code> - значение
        заканчивается (не заканчивается) на текст &quot;value&quot;
      </li>
      <li><Code>attr == &quot;%value%&quot;</Code>, <Code>attr != &quot;%value%&quot;</Code> -
        значение содержит (не содержит) текст &quot;value&quot;
      </li>
    </MarkedList>

    <SectionTitle>Арифметические операции</SectionTitle>

    <MarkedList>
      <li><Code>+</Code> - сложение значений. Для строковых значений - объединение двух значений в одну строку.
      </li>
      <li><Code>-</Code> - вычитание</li>
      <li><Code>*</Code> - умножение</li>
      <li><Code>/</Code> - деление</li>
    </MarkedList>

    <SectionTitle>Булевые операции</SectionTitle>

    <MarkedList>
      <li><Code>||</Code> - ИЛИ</li>
      <li><Code>&amp;&amp;</Code> - И</li>
    </MarkedList>
  </>
));
