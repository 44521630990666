import { createReducer } from "redux-act";
import produce from "immer";

import { INITIAL_STATE } from "./consts";
import {
  checkLimitsReset,
  checkLimitsSet,
  emailNotConfirmedSet,
  emailSet,
  lastProjectsSet,
  loadingSet,
  sessionIdSet,
  usedProjectsSet,
  userErrorSet,
  userReset,
  userSet,
  userSocialErrorSet,
  userSuccessSet,
} from "./actions";

const reducer = createReducer({}, INITIAL_STATE);

reducer.on(userSet, (state, user) => ({
  ...state,
  ...user,
  is_user_requested: true,
}));

reducer.on(
  emailSet,
  produce((state, payload) => {
    state.email = payload;
  }),
);

reducer.on(
  loadingSet,
  produce((state, isLoading) => {
    state.loading = !!isLoading;
  }),
);

reducer.on(
  userSuccessSet,
  produce((state, payload) => {
    state.success = payload || "";
    state.error = undefined;
  }),
);

reducer.on(
  userErrorSet,
  produce((state, payload) => {
    state.error = payload;
  }),
);

reducer.on(
  userSocialErrorSet,
  produce((state, payload) => {
    state.socialError = payload;
  }),
);

reducer.on(
  emailNotConfirmedSet,
  produce((state, payload) => {
    state.emailNotConfirmed = payload;
  }),
);

reducer.on(
  usedProjectsSet,
  produce((state, payload) => {
    state.used_projects = payload;
  }),
);

reducer.on(
  lastProjectsSet,
  produce((state, payload) => {
    state.lastProjects = payload;
  }),
);

reducer.on(
  sessionIdSet,
  produce((state, sessionId) => {
    state.sessionId = sessionId;
  }),
);

reducer.on(
  checkLimitsReset,
  produce((state) => {
    state.checkLimits = {};
  }),
);

reducer.on(userReset, () => ({ ...INITIAL_STATE }));

reducer.on(
  checkLimitsSet,
  produce((state, payload) => {
    state.limits = payload;
  }),
);

export default reducer;
