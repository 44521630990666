import { Api } from "@evergis/api";
import { ApiProvider } from "@evergis/common";
import React from "react";
// import { whyDidYouUpdate } from "why-did-you-update";
// whyDidYouUpdate(React);
import { Provider } from "react-redux";
import { GlobalsContainer, InjectGlobalStyles } from "@evergis/ui";

import { App } from "./components/App/App";
import { ThemeProvider } from "./components/ThemeProvider";
import { api } from "./api/api";
import { configureStore } from "./ducks/configureStore";
import { ExtendedWindow } from "../types/global";
import { RootState } from "./ducks/reducers";

const isClient = typeof window === "object";

const preloadedState = (isClient && (window as ExtendedWindow).__PRELOADED_STATE__) || void 0;

export function createRootComponent(state?: RootState | {}) {
  const store = configureStore(state);

  return () => (
    <ApiProvider api={api as Api}>
      <Provider store={store}>
        <ThemeProvider>
          <InjectGlobalStyles />
          <GlobalsContainer>
            <App />
          </GlobalsContainer>
        </ThemeProvider>
      </Provider>
    </ApiProvider>
  );
}

export const Root = createRootComponent(preloadedState);
