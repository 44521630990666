import { connect } from "react-redux";

import { RootState } from "ducks/reducers";
import { UserState } from "ducks/user";

type ConnectProps = {
  redirectTo: string;
  path: string;
};

export const withUser = connect<void, void, ConnectProps, RootState>(
  ({ user }: { user: UserState }) => ({ user })
);
