import styled from "styled-components";
import { FormContainer as FormContainerUI, Description } from "../../components/styled/forms";
import { HeaderContainer as HeaderContainerUI } from "components/Logo/HeaderWithLogo";
import { stylesFor } from "../../styles/queries";
import { Link } from "../../components/styled/links/Link";

export const HeaderContainer = HeaderContainerUI;

export const FormContainer = styled(FormContainerUI)`
  ${stylesFor.mobile} {
    padding: 0 1rem 1rem;

    > div {
      margin-bottom: 0.5rem;
    }
  }

  ${stylesFor.laptop} {
    
    > ${Link}:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const Desc = styled(Description)`
  color: black;
  
  &:last-of-type {
    margin-bottom: 2rem;
  }

  ${stylesFor.laptop} {
    margin-bottom: 1rem;
    
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`;
