import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { Input, PasswordToggle } from "@evergis/ui";

import { InputContainer } from "components/styled/forms";
import { FormInputsProps } from "./types";

const FormInputsComponent: FC<FormInputsProps> = ({
  error,
  send,
  login,
  loginInput,
  setLogin,
  password,
  passwordInput,
  setPassword,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const sendOnEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        send();
      }
    },
    [send],
  );

  useEffect(() => {
    addEventListener("keydown", sendOnEnter);

    return () => {
      removeEventListener("keydown", sendOnEnter);
    };
  });

  const toggleVisibility = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  return (
    <>
      <InputContainer>
        <Input
          type="text"
          id="login"
          name="login"
          onChange={(e) => setLogin(e.target.value)}
          value={loginInput}
          label={login}
          width={"100%"}
        />
      </InputContainer>

      <InputContainer>
        <Input
          type={isPasswordVisible ? "text" : "password"}
          id="password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={passwordInput}
          label={password}
          width={"100%"}
          error={typeof error !== "string" && !!error?.password}
          help={typeof error !== "string" && !!error?.password ? error.password : ""}
        >
          <PasswordToggle isSelected={isPasswordVisible} onClick={toggleVisibility} />
        </Input>
      </InputContainer>
    </>
  );
};

export const FormInputs = memo(FormInputsComponent);
