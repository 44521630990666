import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { ForWhat as ForWhatRu } from "./ru/ForWhat";
import { ForWhat as ForWhatEn } from "./en/ForWhat";

import { DataTypes as DataTypesRu } from "./ru/DataTypes";
import { DataTypes as DataTypesEn } from "./en/DataTypes";

import { Operators as OperatorsRu } from "./ru/Operators";
import { Operators as OperatorsEn } from "./en/Operators";

const COMPONENTS: Record<string, Record<string, FC>> = {
  ru: {
    forWhat: ForWhatRu,
    dataTypes: DataTypesRu,
    operators: OperatorsRu,
  },
  en: {
    forWhat: ForWhatEn,
    dataTypes: DataTypesEn,
    operators: OperatorsEn,
  }
};

export const QueryLanguage: FC = memo(() => {
  const { i18n: { language } } = useTranslation();

  const ForWhatComponent = COMPONENTS[language].forWhat;
  const DataTypesComponent = COMPONENTS[language].dataTypes;
  const OperatorsComponent = COMPONENTS[language].operators;

  return (
    <>
      <ForWhatComponent />
      <DataTypesComponent />
      <OperatorsComponent />
    </>
  );
});
