import React, { FC, memo } from "react";

import { HelpTitle, MarkedList, OrderedList, Image, ImageTitle, Paragraph } from "../../styled";

import fig1 from "./media/fig-1.png";
import fig2 from "./media/fig-2.png";
import fig3 from "./media/fig-3.png";

export const ConnectToWms: FC = memo(() => (
  <>
    <HelpTitle>Подключение к WMS в QGIS</HelpTitle>

    <OrderedList>
      <li>
        В браузере выбираем пункт меню WMS/WMTS
      </li>
      <li>
        В контекстном меню выбранного пункта выбираем «Создать подключение» (рис. 1)
      </li>
      <li>
        В появившемся окне «Создать новое подключение WMS/WMTS» (рис. 2) заполняем:
        <MarkedList style={{ marginTop: "1rem" }}>
          <li>
            Имя (1) – отображаемое имя
          </li>
          <li>
            Адрес (2) – url адрес WMS сервера (например https://ever.2gis.ru.ru/sp/wms)
          </li>
          <li>
            Во вкладке «Базовая» (3) блока проверка подлинности заполнить поля: имя пользователя (4) и пароль (5) от системы EverGIS
          </li>
        </MarkedList>
      </li>
      <li>
        Добавляем необходимый слой на карту QGIS (рис. 3)
      </li>
    </OrderedList>
    <Paragraph>
      <Image src={fig1} />
      <ImageTitle>Рис. 1</ImageTitle>
    </Paragraph>
    <Paragraph>
      <Image src={fig2} />
      <ImageTitle>Рис. 2</ImageTitle>
    </Paragraph>
    <Paragraph>
      <Image src={fig3} />
      <ImageTitle>Рис. 3</ImageTitle>
    </Paragraph>
  </>
));
