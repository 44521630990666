import { createReducer } from "redux-act";
import produce from "immer";

import { INITIAL_STATE } from "./consts";
import { portalConfigSet } from "./actions";

const reducer = createReducer({}, INITIAL_STATE);

reducer.on(
  portalConfigSet,
  produce((state, payload) => {
    state.config = payload;
  }),
);

export default reducer;
