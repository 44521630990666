import { createReducer } from "redux-act";

import { INITIAL_STATE } from "./consts";
import { changeThemeName } from "./actions";

export const reducer = createReducer({}, INITIAL_STATE);

reducer.on(changeThemeName, (state, themeName) => ({ ...state, themeName }));

export default reducer;
