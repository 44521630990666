import styled from "styled-components";
import { stylesFor } from "../../../styles/queries";

export const CenteredContentPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 14.75rem);

  ${stylesFor.mobile} {
    height: auto;
  }

  ${stylesFor.laptop} {
    height: auto;
    min-height: 100vh;
  }
`;
