import React, { FC, memo, useEffect } from "react";

import { PageProgress } from "components/PageProgress";
import { CenteredContentPage } from "components/styled/layout/CenteredContentPage";
import { ErrorMessage, FormContainer } from "components/styled/forms";

import { enhance } from "./enhance";

import { SocAuthCallbackScreenProps } from "./types";

const SocAuthCallbackScreenComponent: FC<SocAuthCallbackScreenProps> = ({
  network,
  socialError,
  socAuthCallback,
}) => {
  useEffect(() => {
    if (network) {
      socAuthCallback(network);
    }
  }, []);

  return (
    <>
      {socialError ? (
        <CenteredContentPage>
          <FormContainer>
            <ErrorMessage>{socialError}</ErrorMessage>
          </FormContainer>
        </CenteredContentPage>
      ) : (
        <PageProgress />
      )}
    </>
  );
};

export const SocAuthCallbackScreen = enhance(memo(SocAuthCallbackScreenComponent));
