import { Dispatch } from "redux";
import { parse } from "query-string";
import { navigate } from "@reach/router";
import { ChangePasswordPayload, SocAuthNetwork, UpdateUserDc } from "@evergis/api";
import { ExtendedUserInfoDc, UserInfoDc } from "@evergis/api/dist/__generated__/data-contracts";

import { SIGN_IN_NEXT_ABLE_ROUTES } from "./consts";
import { ROUTES } from "appConfig";
import { getResponseError } from "utils/user";
import { qs } from "utils/page";
import {
  checkLimitsSet,
  lastProjectsSet,
  loadingSet,
  usedProjectsSet,
  userErrorSet,
  userSocialErrorSet,
  userSet,
  userSuccessSet,
  userReset as userResetAction,
  emailSet as emailSetAction,
  emailNotConfirmedSet,
} from "./actions";
import {
  changeEmail as _changeEmail,
  changePassword as _changePassword,
  checkLimits as _checkLimits,
  deletePhoto as _deletePhoto,
  editUser as _editUser,
  fetchExtendedUserInfo as _fetchExtendedUserInfo,
  fetchUserInfo as _fetchUserInfo,
  getLastProjects as _getLastProjects,
  getUsedProjects as _getUsedProjects,
  setEmailAndPassword as _setEmailAndPassword,
  setPassword as _setPassword,
  signIn as _signIn,
  unbindSocialNetwork as _unbindSocialNetwork,
  uploadPhoto as _uploadPhoto,
} from "api/user";
import { api } from "api/api";
import { ChangeEmail, ServerErrorType, SetEmailAndPassword } from "api/types";
import { ValidationError } from "api";

export const fetchUser = (makeRedirect?: boolean) => async (dispatch: Dispatch) => {
  const userInfo = await _fetchUserInfo();

  dispatch(userSet(userInfo as UserInfoDc));

  if (makeRedirect) {
    successAuthNavigate();
  }
};

export const fetchExtendedUserInfo = () => async (dispatch: Dispatch) => {
  const extUserInfo = await _fetchExtendedUserInfo();

  dispatch(userSet(extUserInfo as ExtendedUserInfoDc));
};

export const successAuthNavigate = () => {
  const nextRoute = parse(window.location.search).next;

  if (nextRoute && SIGN_IN_NEXT_ABLE_ROUTES.some((route) => nextRoute.includes(route))) {
    navigate(nextRoute.toString());
  } else {
    const { redirect_uri } = qs(window.location.search);

    window.location.href = redirect_uri ? `/${redirect_uri}/` : ROUTES.MAP;
  }
};

export const socAuthCallback = (network: SocAuthNetwork) => async (dispatch: Dispatch) => {
  try {
    await api.external.loginCallback();
    await api.socAuthLogin(network);
    await api.account.fetchCurrentUser();

    dispatch<any>(fetchUser(true));
  } catch (error) {
    const newPayload = await getResponseError(error);

    dispatch(userSocialErrorSet(newPayload));
  }
};

export const resetSuccessError = () => (dispatch: Dispatch) => {
  dispatch(userSuccessSet(""));
  dispatch(userErrorSet(""));
  dispatch(userSocialErrorSet(""));
  dispatch(emailNotConfirmedSet(false));
};

export const signIn =
  ({ username, password }: { username: string; password: string }) =>
  (dispatch: Dispatch) => {
    dispatch(emailNotConfirmedSet(false));

    return _signIn({ username, password })
      .then(() => {
        dispatch(userSuccessSet());
        dispatch<any>(fetchUser(true));
      })
      .catch(async (error) => {
        const json = await error.origin.response.json();
        const newPayload = await getResponseError(null, json);

        dispatch(userErrorSet(newPayload));

        if (json.ErrorType === ServerErrorType.EmailNotConfirmed) {
          dispatch(emailNotConfirmedSet(true));
          await api.account.verifyEmail(username);
        }
      });
  };

export const editUser = (payload: Partial<UpdateUserDc>) => (dispatch: Dispatch) => {
  return _editUser(payload)
    .then(() => {
      dispatch(userSuccessSet());
      dispatch(userSet(payload));
    })
    .catch((error) => {
      let newPayload: any;

      if (error instanceof ValidationError) {
        newPayload = Object.values(error.payload).join(",");
      } else {
        newPayload = error.message;
      }

      dispatch(userErrorSet(newPayload));
    });
};

export const uploadPhoto = (file?: File, payload?: { base64Image?: string }) => async (dispatch: Dispatch) => {
  try {
    await _uploadPhoto(file, payload);
    dispatch<any>(fetchExtendedUserInfo());
  } catch (error) {
    let newPayload: any;

    if (error instanceof ValidationError) {
      newPayload = error.payload;
    } else {
      newPayload = error.message;
    }

    dispatch(userErrorSet(newPayload));
  }
};

export const deletePhoto = () => async (dispatch: Dispatch) => {
  try {
    await _deletePhoto();
    dispatch<any>(fetchExtendedUserInfo());
  } catch (error) {
    let newPayload: any;

    if (error instanceof ValidationError) {
      newPayload = error.payload;
    } else {
      newPayload = error.message;
    }

    dispatch(userErrorSet(newPayload));
  }
};

export const fetchPreview1 = () => async (dispatch: Dispatch) => {
  const url = api.accountPreview.getPreview("");
  const response = await fetch(url);
  const profile_photo = response.headers.get("content-type")?.includes("text/plain")
    ? await response.text()
    : `${url}?r=${Math.random()}`;

  dispatch(
    userSet({
      has_profile_photo: true,
      profile_photo,
    }),
  );
};

export const changePassword = (payload: ChangePasswordPayload) => (dispatch: Dispatch) => {
  return _changePassword(payload)
    .then(() => {
      dispatch(userSuccessSet("Пароль успешно изменен"));
    })
    .catch(async (error) => {
      const errorMessage = await getResponseError(error);

      dispatch(userErrorSet(errorMessage));
    });
};

export const changeEmail = (payload: ChangeEmail) => (dispatch: Dispatch) => {
  return _changeEmail(payload)
    .then(() => {
      dispatch(userSuccessSet(`Письмо для подтверждения было выслано на ${payload.newEmail}`));
    })
    .catch(async (error) => {
      const errorMessage = await getResponseError(error);

      dispatch(userErrorSet(errorMessage));
    });
};

export const setPassword = (payload: string) => (dispatch: Dispatch) => {
  return _setPassword(payload)
    .then(() => {
      dispatch(userSuccessSet("Пароль успешно задан"));
      dispatch<any>(fetchUser());
    })
    .catch(async (error) => {
      const errorMessage = await getResponseError(error);

      dispatch(userErrorSet(errorMessage));
    });
};

export const setEmailAndPassword = (payload: SetEmailAndPassword) => (dispatch: Dispatch) => {
  return _setEmailAndPassword(payload)
    .then(() => {
      dispatch(userSuccessSet(`Письмо для подтверждения было выслано на ${payload.email}`));
      dispatch<any>(fetchUser());
    })
    .catch(async (error) => {
      const errorMessage = await getResponseError(error);

      dispatch(userErrorSet(errorMessage));
    });
};

export const bindSocialNetwork = () => (dispatch: Dispatch) => {
  return api.external
    .bindCallback()
    .then(() => {
      dispatch<any>(fetchExtendedUserInfo());
    })
    .catch(async (error) => {
      const errorMessage = await getResponseError(error);

      dispatch(userSocialErrorSet(errorMessage));
    });
};

export const unbindSocialNetwork = (payload: SocAuthNetwork) => async (dispatch: Dispatch) => {
  try {
    await _unbindSocialNetwork(payload);

    dispatch<any>(fetchExtendedUserInfo());
  } catch (error) {
    const errorMessage = await getResponseError(error);

    dispatch(userSocialErrorSet(errorMessage));
  }
};

export const getLastProjects = () => async (dispatch: Dispatch) => {
  const lastProjects: any = await _getLastProjects();

  dispatch(lastProjectsSet(lastProjects));
  dispatch(userSuccessSet());
};

export const getUsedProjects = () => async (dispatch: Dispatch) => {
  dispatch(loadingSet(true));

  const usedProjects = await _getUsedProjects();

  dispatch(usedProjectsSet(usedProjects));

  dispatch(loadingSet(false));
};

export const userReset = () => async (dispatch: Dispatch) => {
  dispatch(userResetAction());
};

export const emailSet = (email: string) => async (dispatch: Dispatch) => {
  dispatch(emailSetAction(email));
};

export const getCheckLimits = () => async (dispatch: Dispatch) => {
  const limits = await _checkLimits();

  dispatch(checkLimitsSet(limits));
  dispatch(userSuccessSet());
};
