import { createStructuredSelector } from "reselect";

import { RootState } from "ducks/reducers";
import { getUserSelector, UserState } from "ducks/user";

const structuredSelectors = createStructuredSelector<RootState, { user: UserState }>({
  user: getUserSelector,
});

export default structuredSelectors;
