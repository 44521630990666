import { FetchOptions, Methods, ValidationErrorPayload } from "./types";

const BAD_REQUEST = 400;

export class ValidationError<Fields extends object = object> {
  name = "ValidationError";
  message = "Check request data";
  stack = new Error().stack;
  payload: ValidationErrorPayload<Fields>;

  constructor(payload: ValidationErrorPayload<Fields>) {
    this.payload = payload;
  }

  toString() {
    return `${this.name}: ${this.message}
      ${this.stack}
    `;
  }
}

export async function _fetch<Response>(
  method: Methods = "GET",
  url: string,
  { headers, ...options }: FetchOptions
): Promise<Response> {
  const response = await fetch(url, {
    headers: {
      Accept: "application/json",
      ...headers,
    },
    credentials: "include",
    ...options,
    method,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parsedResponse: any = void 0;

  const contentTypeHeader = response.headers.get("content-type");

  if (contentTypeHeader && contentTypeHeader.includes("application/json")) {
    parsedResponse = await response.json();
    if (parsedResponse.redirectUrl){
      window.location = parsedResponse.redirectUrl;
    }
  } else if (contentTypeHeader) {
    parsedResponse = await response.text();
  }

  if (response.ok) {
    return parsedResponse;
  } if (response.status === BAD_REQUEST) {
    throw new ValidationError(parsedResponse);
  } else {
    throw new Error("Ошибка сервера");
  }
}
