import { createStructuredSelector } from "reselect";

import { RootState } from "ducks/reducers";
import { getProfilePhotoSelector, hasProfilePhotoSelector, isUserRequested, UserState } from "ducks/user";

const structuredSelectors = createStructuredSelector<RootState, Partial<UserState>>({
  is_user_requested: isUserRequested,
  has_profile_photo: hasProfilePhotoSelector,
  profile_photo: getProfilePhotoSelector,
});

export default structuredSelectors;
