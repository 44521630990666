import styled from "styled-components";
import { transition, Flex, H1, RaisedButton, Blank, Image as ImageOrigin } from "@evergis/ui";

import { Link as LinkComponent, Link as RouterLink } from "components/styled/links/Link";
import { ContainerWithPadding } from "../../components/styled/layout/ContainerWithPadding";
import { Video } from "../../components/Video/Video";
import { stylesFor } from "../../styles/queries";

export const Page = styled(ContainerWithPadding)`
  padding-top: 0;
  flex-direction: column;
  align-items: center;
`;

export const WindowHeightContainer = styled(Flex)`
  padding: 1rem 0 0;
  width: 100%;
  align-items: center;
  height: calc(100vh - 1rem);
  background: #000;
`;

export const MainContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-top: 4.875rem;
  width: 100%;
  height: 100%;

  ${stylesFor.mobile} {
    overflow: hidden;
    position: relative;
  }
`;

export const Title = styled(H1)`
  font-size: 56px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 2.625rem;
  z-index: 1;

  ${stylesFor.mobile} {
    font-size: 1.5rem;
  }
`;

export const VideoContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 940px;
  height: 380px;

  ${stylesFor.mobile} {
    width: 160%;
  }
`;

export const ButtonAndProjectsContainer = styled(Flex)`
  align-items: center;
  margin-bottom: 2rem;
`;

export const OpenAppButton = styled(RaisedButton)`
  background: radial-gradient(8.6em at 50% 200%, #00aaff, #0062b8);
  font-size: 1.25rem;

  &:hover {
    background: radial-gradient(8.6em at 50% 200%, #63cbff, #0062b8);
  }

  &:active {
    background: radial-gradient(8.6em at 50% 150%, #63cbff, #0062b8);
  }

  &:disabled {
    background: ${({ theme: { palette } }) =>
      `radial-gradient(8.6rem at 50% 200%, ${palette.icon}, ${palette.iconDisabled})`};
    color: #000;
  }
`;

export const ProjectsContainer = styled(Flex)`
  margin-top: 4rem;
`;

export const ProjectBlock = styled(Flex)`
  &:not(:first-of-type) {
    margin-left: 1rem;
  }
`;

export const PlayButton = styled.span`
  position: relative;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 0.125rem solid rgba(255, 255, 255, 0.65);
  background: none;
  box-sizing: border-box;

  :after {
    content: "";
    position: absolute;
    top: 50%;
    left: 1.15rem;
    transform: translateY(-50%);

    width: 0;
    height: 0;
    border-top: 0.45rem solid transparent;
    border-bottom: 0.45rem solid transparent;

    border-left: 0.6875rem solid #fff;
  }
`;

export const PlayText = styled.span`
  margin-left: 1rem;
  opacity: 0.7;
  color: #fff;
`;

export const PlayVideoContainer = styled(RouterLink)`
  display: flex;
  margin-top: 4.125rem;
  align-items: center;
  cursor: pointer;

  &,
  &:visited,
  &:active,
  &:hover {
    &&[data-current="true"] {
      color: #fff;
    }
  }
`;

export const ProjectImage = styled(ImageOrigin)`
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.smallest};
  width: 3.125rem;
  height: 3.125rem;
  cursor: pointer;
  transition: all ${transition.release};

  &:not(:first-of-type) {
    background: coral;
  }

  &:nth-of-type(3) {
    background: lightblue;
  }

  &:hover {
    transform: scale(1.12);
    transition: all ${transition.hover};
  }
`;

export const BackgroundVideo = styled(Video)`
  width: 100%;
`;

export const Container = styled(Flex)`
  height: 30.6875rem;
  width: 100%;
  max-width: 71rem;
  flex-wrap: nowrap;
  padding-bottom: 15.3125rem;

  @media (max-width: 1440px) {
    max-width: 61.25rem;
  }

  ${stylesFor.mobile} {
    height: auto;
    padding: 0;
  }

  ${stylesFor.laptop} {
    padding: 0 2rem;
    box-sizing: border-box;
    margin-bottom: 6.3125rem;
  }
`;

export const UserCardsTitle = styled(H1)`
  opacity: 0.87;
  font-size: 2.25rem;
  margin: 8rem 0 0.5rem;

  ${stylesFor.mobile} {
    margin: 2rem 1rem 0.5rem;
    font-size: 1rem;
  }

  ${stylesFor.laptop} {
    margin-top: 3rem;
  }
`;

export const MoreCardsTitle = styled.span`
  ${stylesFor.mobile} {
    margin: 0 1rem;
    font-size: 1rem;
  }
`;

export const Subtitle = styled.span`
  opacity: 0.54;
`;

export const UserCardsProjectsContainer = styled(Flex)`
  margin-top: 3rem;

  ${stylesFor.mobile} {
    margin: 1rem 1rem;
  }
`;

export const UserCardsProjectBlock = styled(Blank)`
  position: relative;
  width: 18rem;
  height: 15.375rem;
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.smallest};
  overflow: hidden;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-left: 1rem;
  }

  ${stylesFor.mobile} {
    width: calc(100vw - 2rem);
    height: 67vw;
    margin-bottom: 1rem;

    &:not(:first-of-type) {
      margin-left: 0;
    }
  }
`;

export const Image = styled(ImageOrigin)`
  width: 18rem;
  height: 15.375rem;

  ${stylesFor.mobile} {
    width: calc(100vw - 2rem);
    height: 67vw;
  }
`;

export const ProjectName = styled(Flex)`
  width: calc(100% - 3rem);
  padding: 0 1.5rem;
  position: absolute;
  bottom: -4.625rem;
  left: 0;
  background: rgba(0, 0, 0, 0.54);
  transition: bottom ${transition.hide};
  cursor: pointer;

  &:hover,
  ${ProjectBlock}:hover & {
    bottom: 0;
    transition: bottom ${transition.show};
  }
`;

export const ProjectTitle = styled.span`
  font: ${({ theme: { fonts } }) => fonts.subtitle};
  color: #fff;
  padding: 1rem 0;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  max-width: 15rem;
`;

export const ProjectDescription = styled.div`
  font: ${({ theme: { fonts } }) => fonts.description};
  opacity: 0;
  color: #fff;
  max-height: 1.75rem;
  max-width: 15rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity ${transition.hide};

  ${ProjectBlock}:hover &,
  ${ProjectName}:hover & {
    opacity: 0.7;
    transition: opacity ${transition.show};
  }
`;

export const CardCreator = styled.span`
  padding: 1rem 0;
  font: ${({ theme: { fonts } }) => fonts.description};
  opacity: 0;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  max-width: 15rem;
  transition: opacity ${transition.hide};

  ${ProjectBlock}:hover &,
  ${ProjectName}:hover & {
    opacity: 0.7;
    transition: opacity ${transition.show};
  }
`;

export const Date = styled.span`
  opacity: 0.54;
  color: #000;
  font: ${({ theme: { fonts } }) => fonts.description};
  margin-top: 0.375rem;
  width: 3.765rem;
`;

export const Descr = styled.span`
  opacity: 0.87;
  margin-top: 0.375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  max-width: 55.36rem;
`;

export const Link = styled(LinkComponent)`
  font: ${({ theme: { fonts } }) => fonts.subtitle};
`;
