/* eslint-disable max-lines */
import React, { FC, memo, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";

import { Flex, Menu, FocusTrap, useToggle, Popup, UnicodeSmall, getUnicodeFromString, ThemeName } from "@evergis/ui";

import {
  RightButtonsContainer,
  Popover,
  ProfileButtonBlock,
  Name,
  Username,
  PopupContainer,
  SignInLink,
  AvatarContainer,
  AvatarImage,
  LanguageButtonContainer,
  LanguageButton,
  LanguageMenuItem,
} from "./styled";
import { ThemeProvider } from "../ThemeProvider";

import { ROUTES } from "../../appConfig";
import { api } from "../../api/api";

import { i18n } from "../../i18n";
import { userReset } from "../../ducks/user";
import { getPortalConfigSelector } from "../../ducks/portalConfig";

import { IOption, RightButtonsProps } from "./types";

import stub from "../../../public/stub.png";
import ru from "../../../public/rus.svg";
import en from "../../../public/eng.svg";

const LANGUAGES = {
  en: "en",
  ru: "ru",
};

type HeaderUserLinkOption = IOption & {
  action?: (dispatch: (...args: unknown[]) => void) => void;
  roles?: string[];
  external?: boolean;
};

// eslint-disable-next-line max-lines-per-function
const RightButtonsComponent: FC<RightButtonsProps> = ({
  observerScroll,
  first_name,
  last_name,
  roles,
  has_profile_photo,
  profile_photo,
  username,
  isMobile,
  isDark,
}) => {
  // eslint-disable-next-line id-length
  const { t } = useTranslation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [focusedOption, setFocusedOption] = useState<string | null>("");
  const dispatch = useDispatch();
  const input = useRef<HTMLInputElement>();
  const [language, setLanguage] = useState<string>(i18n.language);
  const [isVisible, togglePopup] = useToggle(false);
  const config = useSelector(getPortalConfigSelector);
  // TODO: add localization prop to PortalConfiguration
  // @ts-ignore
  const { localization } = config?.settings || {};

  const headerUserLinks: HeaderUserLinkOption[] = useMemo(
    () => [
      {
        value: ROUTES.PROFILE,
        text: t("profile"),
      },
      {
        value: ROUTES.ADMIN,
        text: t("admin"),
        roles: ["__superuser"],
        external: true,
      },
      {
        value: ROUTES.LOGOUT,
        text: t("logOut"),
        action: (dispatchFn) => {
          api.account.logout().then(() => {
            dispatchFn(userReset());
            navigate(ROUTES.MAIN);
          });
        },
      },
    ],
    [t],
  );

  const toggleProfileMenu = useCallback(() => {
    setShowProfileMenu(!showProfileMenu);

    if (input.current) {
      input.current.focus();
    }
  }, [input.current, showProfileMenu]);

  const selectOption = useCallback(
    ([item]: IOption[]) => {
      toggleProfileMenu();

      const { value, action, external } = item as HeaderUserLinkOption;

      if (action) {
        action(dispatch);
      } else if (external) {
        window.location.href = `${value}/`;
      } else {
        navigate(value);
      }
    },
    [dispatch, toggleProfileMenu],
  );

  const goToLastOption = useCallback(() => {
    const lastFocusedItem = headerUserLinks[headerUserLinks.length - 1];

    setFocusedOption(lastFocusedItem.value);
  }, [headerUserLinks]);

  const goToFirstOption = useCallback(() => {
    const firstFocusedItem = headerUserLinks[0];

    setFocusedOption(firstFocusedItem.value);
  }, [headerUserLinks]);

  const goToNextOption = useCallback(() => {
    const currentIndex = headerUserLinks.findIndex(({ value }: IOption) => value === focusedOption);
    const nextElementIndex = currentIndex + 1;

    if (nextElementIndex < headerUserLinks.length) {
      const nextFocusedItem = headerUserLinks[nextElementIndex];

      setFocusedOption(nextFocusedItem.value);
    }
  }, [focusedOption, headerUserLinks]);

  const goToPreviousOption = useCallback(() => {
    const currentIndex = headerUserLinks.findIndex(({ value }: IOption) => value === focusedOption);

    const prevElementIndex = currentIndex - 1;

    if (prevElementIndex > -1) {
      const prevFocusedItem = headerUserLinks[prevElementIndex];

      setFocusedOption(prevFocusedItem.value);
    }
  }, [focusedOption, headerUserLinks]);

  const selectOptionWithEnter = useCallback(() => {
    let items: IOption[] = [];

    for (const key of headerUserLinks) {
      if (key.value === focusedOption) {
        items = items.concat([key]);
      }
    }
    selectOption(items);
  }, [focusedOption, selectOption, headerUserLinks]);

  const openMenuWithEnter = useCallback(() => {
    toggleProfileMenu();

    const newFocusedItem = headerUserLinks[0];

    setFocusedOption(newFocusedItem.value);
  }, [toggleProfileMenu, headerUserLinks]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    observerScroll(() => {
      setShowProfileMenu(false);
    });
  }, []);

  const renderAnchor = useMemo(
    () => (
      <ProfileButtonBlock onClick={toggleProfileMenu}>
        <AvatarContainer>
          {!profile_photo || profile_photo?.includes(".") ? (
            <AvatarImage height={40} width={40} src={has_profile_photo ? profile_photo : ""} noFetch fallback={stub} />
          ) : (
            <UnicodeSmall>{getUnicodeFromString(profile_photo)}</UnicodeSmall>
          )}
        </AvatarContainer>
        {!isMobile && (
          <Flex column>
            <Name username={username} dark={isDark}>
              {first_name} {last_name}
            </Name>
            <Username name={first_name || last_name} dark={isDark}>
              {username}
            </Username>
          </Flex>
        )}
        <FocusTrap
          innerRef={(ref) => (input.current = ref)}
          onArrowLeft={goToFirstOption}
          onArrowRight={goToLastOption}
          onArrowUp={goToPreviousOption}
          onArrowDown={goToNextOption}
          onDel={toggleProfileMenu}
          onBackspace={toggleProfileMenu}
          onEsc={toggleProfileMenu}
          onSpace={selectOptionWithEnter}
          onEnter={showProfileMenu ? selectOptionWithEnter : openMenuWithEnter}
        />
      </ProfileButtonBlock>
    ),
    [
      username,
      has_profile_photo,
      profile_photo,
      stub,
      isMobile,
      isDark,
      first_name,
      last_name,
      goToFirstOption,
      goToLastOption,
      toggleProfileMenu,
      goToPreviousOption,
      goToNextOption,
      selectOptionWithEnter,
      showProfileMenu,
      selectOptionWithEnter,
      openMenuWithEnter,
    ],
  );

  const menuOptions = useMemo(
    () => headerUserLinks.filter((link) => !link.roles?.length || link.roles?.some((role) => roles?.includes(role))),
    [roles, headerUserLinks],
  );

  return (
    <RightButtonsContainer>
      <ThemeProvider themeName={isDark ? ThemeName.Dark : ThemeName.Light}>
        {localization !== false && (
          <Popup
            placement="left-start"
            anchor={(ref) => {
              return (
                <LanguageButtonContainer ref={ref}>
                  <LanguageButton img={language === LANGUAGES.ru ? ru : en} dark={!isDark} onClick={togglePopup} />
                </LanguageButtonContainer>
              );
            }}
            isVisible={isVisible}
            onRequestClose={togglePopup}
            distance={-40}
          >
            <Menu
              selectOption={(values) => {
                if (!Array.isArray(values)) {
                  return;
                }

                setLanguage(values[0]?.value as SetStateAction<string>);
                togglePopup();
              }}
              options={[
                {
                  text: <LanguageMenuItem>Русский</LanguageMenuItem>,
                  value: LANGUAGES.ru,
                },
                {
                  text: <LanguageMenuItem>English</LanguageMenuItem>,
                  value: LANGUAGES.en,
                },
              ]}
            />
          </Popup>
        )}
        {!api.account.isAuth ? (
          <SignInLink dark={isDark} to={ROUTES.SIGN_IN}>
            {t("signIn")}
          </SignInLink>
        ) : (
          <Popover
            open={showProfileMenu}
            animateX={false}
            onRequestClose={toggleProfileMenu}
            anchorOrigin={"bottom-right"}
            targetOrigin={"top-right"}
            fixedAnchor
            autoPosition={false}
            anchor={renderAnchor}
          >
            <PopupContainer>
              <Menu
                options={menuOptions}
                selectOption={selectOption}
                setFocusedOption={(item) => setFocusedOption(item.value as SetStateAction<string | null>)}
              />
            </PopupContainer>
          </Popover>
        )}
      </ThemeProvider>
    </RightButtonsContainer>
  );
};

export const RightButtons = memo(RightButtonsComponent);
