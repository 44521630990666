import React, { FC, memo, useEffect } from "react";

import { VideoProps } from "./types";

const VideoComponent: FC<VideoProps> = ({
  src = "",
  gif = "",
  className,
  videoProps,
}) => {
  let videoEl: HTMLVideoElement | null = null;

  useEffect(() => {
    if (videoEl) {
      videoEl.load();
      videoEl.play();
    }
  }, [videoEl]);

  return (
    <video
      poster={gif}
      autoPlay
      muted
      loop
      ref={ref => (videoEl = ref)}
      className={className}
      {...videoProps}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export const Video = memo(VideoComponent);
