import { createReducer, Handler } from "redux-act";
import produce from "immer";

import { INITIAL_STATE } from "./consts";
import { addNotification, closeNotification } from "./actions";
import { MessageType, NotificationsState } from "./types";

const reducer = createReducer({}, INITIAL_STATE);

reducer.on(
  addNotification,
  produce<NotificationsState, MessageType["id"][]>((state, message) => {
    state.messages?.push(message as MessageType);
  }) as Handler<NotificationsState, MessageType>
);

reducer.on(
  closeNotification,
  produce<NotificationsState, MessageType["id"][]>((state, id) => {
    state.messages = state.messages?.filter(item => item.id !== id);
  }) as Handler<NotificationsState, MessageType["id"]>
);

export default reducer;
