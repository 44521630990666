import React, { FC } from "react";

import { PageLayout } from "../components/PageLayout";
import { WmsQgis } from "../../help/wms-qgis";

export const WmsQgisPage: FC = () => (
  <PageLayout>
    <WmsQgis />
  </PageLayout>
);
