import React from "react";
import { render, hydrate } from "react-dom";
import { Root } from "./root";
import { loadComponents } from "loadable-components";

let isInitialRender = true;
const targetNode = document.getElementById("app") as HTMLElement;

function renderApp(Component: typeof Root, node: HTMLElement) {
  if (
    isInitialRender &&
    targetNode.hasChildNodes() &&
    typeof hydrate === "function"
  ) {
    hydrate(<Component />, node);
    isInitialRender = false;
  } else {
    render(<Component />, node);
  }
}

loadComponents()
  // eslint-disable-next-line no-console
  .catch(console.warn)
  .finally(() => {
    renderApp(Root, targetNode);
  });
